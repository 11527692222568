import { Outlet } from 'react-router-dom';
import { useEffect } from 'react';
import Cookies from 'js-cookie';

import { initMixpanel, initSentry, setUserProperties } from './utils';
import { useAuthContext } from './data-access';

initSentry();
initMixpanel();

const App = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const redirectTo = urlParams.get('redirect_to');
  const via = urlParams.get('via');
  const { user } = useAuthContext();

  useEffect(() => {
    if (!user) {
      return;
    }

    setUserProperties(user);
  }, [user]);

  useEffect(() => {
    if (redirectTo) {
      Cookies.set('redirect_to', redirectTo);
    }
    if (via) {
      Cookies.set('via', via);
    }
  }, [redirectTo, via]);

  return (
    <div className="wrapper">
      <Outlet />
    </div>
  );
};

export default App;

import './DailyAnalysisModalView.scss';
import Logo from '../../../../../../assets/logo/logo.png';
import Background from '../../../../../../assets/actions/daily-analysis-bg.png';
import { Gauge, gaugeClasses } from '@mui/x-charts/Gauge';
import { useCircadianAnalysis } from '../../../../../../data-access';
import { useMemo } from 'react';
import { getColorByEnergyScore, getGuidanceByPeakEnergy } from './helpers';
import { getCssVariable } from '../../../../../../utils';

const greenBaseColor = getCssVariable('--color-green-base');
const redBaseColor = getCssVariable('--color-red-base');

const DailyAnalysisModalView = () => {
  const {
    selectedDateHighEnergyDuration,
    selectedDatePeakEnergy,
    avgPeakEnergy,
    isFetchingAvgCircadian,
    isFetchingSelectedCircadian,
  } = useCircadianAnalysis();

  const { peakEnergyDiff, peakEnergyDiffColor, peakEnergyDiffSymbol } = useMemo(() => {
    const diff = selectedDatePeakEnergy - avgPeakEnergy;
    const peakEnergyDiff = Math.round(diff);
    const peakEnergyDiffColor = diff >= 0 ? greenBaseColor : redBaseColor;
    const peakEnergyDiffSymbol = diff > 0 ? '+' : '';

    return { peakEnergyDiff, peakEnergyDiffColor, peakEnergyDiffSymbol };
  }, [selectedDatePeakEnergy, avgPeakEnergy]);

  const targetColor = useMemo(
    () => getColorByEnergyScore(selectedDatePeakEnergy),
    [selectedDatePeakEnergy]
  );

  return (
    <div className="daily-analysis-modal">
      <div className="daily-analysis-modal__energy-container">
        <img
          src={Logo}
          draggable={'false'}
          alt="logo"
          className="daily-analysis-modal__energy-container__logo"
        />
        <img
          src={Background}
          draggable={'false'}
          alt="logo"
          className="daily-analysis-modal__energy-container__bg"
        />
        <Gauge
          width={299}
          height={148}
          value={selectedDatePeakEnergy}
          startAngle={-90}
          endAngle={90}
          innerRadius={80}
          className="daily-analysis-modal__energy-container__gauge"
          sx={(theme) => ({
            [`& .${gaugeClasses.valueText}`]: {
              fontSize: 48,
              fontWeight: 700,
              transform: 'translateY(-40px)',
            },
            [`& .${gaugeClasses.valueArc}`]: {
              fill: targetColor,
            },
            [`& .${gaugeClasses.referenceArc}`]: {
              fill: theme.palette.text.disabled,
            },
            [`& .${gaugeClasses.valueText} text`]: {
              fill: targetColor,
            },
          })}
        />
        <p className="daily-analysis-modal__energy-container__title">Peak energy</p>
        <p className="daily-analysis-modal__energy-container__guidance-text">
          {getGuidanceByPeakEnergy(selectedDatePeakEnergy, avgPeakEnergy)}
        </p>
        <div className="daily-analysis-modal__energy-container__energy-stats">
          <div className="daily-analysis-modal__energy-container__stat-card">
            <p
              className="daily-analysis-modal__energy-container__stat-card-value"
              style={{ color: targetColor }}
            >
              {selectedDateHighEnergyDuration}
            </p>
            <p className="daily-analysis-modal__energy-container__stat-card-label">
              High Energy Duration
            </p>
          </div>
          <div className="daily-analysis-modal__energy-container__stat-card">
            <p
              className="daily-analysis-modal__energy-container__stat-card-value"
              style={{ color: peakEnergyDiffColor }}
            >
              {peakEnergyDiffSymbol} {peakEnergyDiff}
            </p>
            <p className="daily-analysis-modal__energy-container__stat-card-label">Avg Energy</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DailyAnalysisModalView;

import { useCallback } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { TaskItem } from '@demind-inc/core';

import { useAuthContext, useUpdateTodoTask } from '../data-access';
import { trackEventMixpanel } from '../utils';

export interface MoveTaskParams {
  task: TaskItem;
  targetDate: Dayjs;
  wasOverdue?: boolean;
}

export const useMoveTask = () => {
  const { user } = useAuthContext();
  const { updateTodoTask, isUpdating: isMovingTask } = useUpdateTodoTask();

  const handleMoveTask = useCallback(
    async ({ task, targetDate, wasOverdue = false }: MoveTaskParams) => {
      if (!user.userId) {
        return;
      }
      const hasDueDateTime = !!task.dueDateTime?.datetime;
      const hasStartDate = !!task.startDateTime?.datetime;

      let targetDueDatetime = targetDate;
      let targetStartDatetime = targetDate;

      if (hasDueDateTime) {
        const prevDueDate = dayjs(task.dueDateTime.datetime);
        const dueDateHours = prevDueDate.hour();
        const dueDateMinutes = prevDueDate.minute();
        targetDueDatetime = targetDate.hour(dueDateHours).minute(dueDateMinutes);
      }

      if (hasStartDate) {
        const prevStartDate = dayjs(task.startDateTime.datetime);
        const startDateHours = prevStartDate.hour();
        const startDateMinutes = prevStartDate.minute();
        targetStartDatetime = targetDate.hour(startDateHours).minute(startDateMinutes);
      }

      try {
        await updateTodoTask({
          boardId: task.boardId,
          taskId: task.taskId,
          userId: user.userId,
          newTaskInfo: {
            ...task,
            ...(hasStartDate
              ? {
                  startDateTime: {
                    datetime: targetStartDatetime.toISOString(),
                    date: targetStartDatetime.format('YYYY-MM-DD'),
                    timezone: dayjs.tz.guess(),
                  },
                }
              : {}),
            dueDateTime: {
              date: dayjs(targetDueDatetime).format('YYYY-MM-DD'),
              ...(hasDueDateTime ? { datetime: dayjs(targetDueDatetime).toISOString() } : {}),
              timezone: dayjs.tz.guess(),
            },
            appFrom: task.appFrom,
          },
          movedTaskParam: {
            prevDate:
              task.dueDateTime.datetime || task.dueDateTime.date
                ? dayjs(task.dueDateTime.datetime || task.dueDateTime.date).format('YYYY-MM-DD')
                : undefined,
            newDate: targetDate.format('YYYY-MM-DD'),
            wasOverdue,
          },
        });
        trackEventMixpanel('move_date_of_task');
      } catch (error) {
        console.error('Failed to update task:', error);
      }
    },
    [user.userId]
  );

  return { handleMoveTask, isMovingTask };
};

import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { CalendlyConfigurePage } from '../pages';

export const SettingsRoutes: FC = () => {
  return (
    <Routes>
      <Route path={'calendly/configure'} element={<CalendlyConfigurePage />} />
    </Routes>
  );
};

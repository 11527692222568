import React from 'react';
import { Dayjs } from 'dayjs';

import './DateBox.scss';

interface DateBoxProps {
  date: Dayjs;
}

const DateBox: React.FC<DateBoxProps> = ({ date }) => {
  return (
    <div className="date-box">
      <span className="date-box__day">{date.format('MMM')}</span>
      <span className="date-box__date">{date.get('dates')}</span>
    </div>
  );
};

export default DateBox;

import { ReactComponent as MenuCloseIcon } from '../../../assets/icons/menu-close-icon.svg';
import { ReactComponent as MenuOpenIcon } from '../../../assets/icons/menu-open-icon.svg';
import { ReactComponent as SettingIcon } from '../../../assets/icons/setting-icon.svg';
import { ReactComponent as ChromeExtensionIcon } from '../../../assets/icons/chrome-extension-icon.svg';
import { ReactComponent as MobileIcon } from '../../../assets/icons/mobile-icon.svg';

type SvgComponent = React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
type MenuItem = {
  icon: SvgComponent;
  text: string;
  key: string;
  onClick?: () => void;
};

export const SIDEBAR_MENU = {
  open: MenuOpenIcon,
  close: MenuCloseIcon,
  setting: SettingIcon,
  chromeExtension: ChromeExtensionIcon,
  mobile: MobileIcon,
};


export const MENU_ITEMS: MenuItem[] = [
  {
    icon: SIDEBAR_MENU.mobile,
    text: 'Mobile App',
    key: 'mobile',
  },
  {
    icon: SIDEBAR_MENU.chromeExtension,
    text: 'Chrome Extension',
    key: 'chromeExtension',
    onClick: () =>
      window.open(
        'https://chromewebstore.google.com/detail/lifestack-extension/mbpcaihbaecjnndomdpkigmmhdnfccjb',
        '_blank'
      ),
  },
  {
    icon: SIDEBAR_MENU.setting,
    text: 'Settings',
    key: 'settings',
  },
];

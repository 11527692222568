import { FC } from 'react';
import clsx from 'clsx';

import './EventBlock.scss';
import { RBCEvent } from '../types';
import { getCssVariable } from '../../../../utils';

interface EventBlockProps {
  event: Partial<RBCEvent>;
  className?: string;
}

const primaryColor = getCssVariable('--color-primary');

const EventBlock: FC<EventBlockProps> = ({ event, className }) => {
  return (
    <div className={clsx('event-block', className)}>
      {/* Exclude Zone  */}
      {event.eventId && (
        <div
          className="event-block__calendar"
          style={{ backgroundColor: event.calendarColor || event.color || primaryColor }}
        />
      )}
      <span
        className="event-block__title"
        style={{
          color: event.color || primaryColor,
          textDecorationLine: event.isDeclined ? 'line-through' : 'none',
        }}
      >
        {event.title}
      </span>
    </div>
  );
};

export default EventBlock;

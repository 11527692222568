import React, { useMemo } from 'react';
import { Calendar } from '@demind-inc/core';
import { Select, MenuItem, FormControl, InputLabel, SelectChangeEvent } from '@mui/material';
import { AccountCircle, Check } from '@mui/icons-material';
import './CalendarAccountDropdown.scss';
import { useAuthContext } from '../../../../data-access';
import { toUpper } from 'lodash';

interface CalendarAccountDropdownProps {
  calendars: Calendar[];
  selectedCalendarId: string;
  onSelectCalendarId: (calendarId: string) => void;
}

const CalendarAccountDropdown: React.FC<CalendarAccountDropdownProps> = ({
  calendars,
  selectedCalendarId,
  onSelectCalendarId,
}) => {
  const { user } = useAuthContext();
  const sortedCalendars = useMemo(() => {
    return calendars
      .filter(({ visible }) => visible)
      .sort((_, info) => (info.default ? 1 : -1))
      .sort((a) => (a.calendarType === 'google' ? 1 : 0))
      .sort((a) => (a.rootEmail === user.email ? 1 : 0));
  }, [calendars]);

  const handleChange = (event: SelectChangeEvent<string>) => {
    onSelectCalendarId(event.target.value);
  };

  const selectedCalendar = calendars.find((calendar) => calendar.calendarId === selectedCalendarId);

  return (
    <FormControl className="calendar-account-dropdown__form-control">
      <Select
        labelId="calendar-account-label"
        id="calendar-account-select"
        variant="standard"
        value={selectedCalendarId}
        onChange={handleChange}
        className="calendar-account-dropdown__select"
        renderValue={() => (
          <div className="calendar-account-dropdown__selected-value">
            <div className="calendar-account-dropdown__selected-value__icon-container">
              <AccountCircle fontSize="medium" />
            </div>
            <span className='calendar-account-dropdown__selected-value__text'>{toUpper(selectedCalendar?.name) || 'Select a calendar'}</span>
          </div>
        )}
      >
        {sortedCalendars.map((calendar) => (
          <MenuItem
            key={calendar.calendarId}
            value={calendar.calendarId}
            className="calendar-account-dropdown__menu-item"
          >
            <div className="calendar-account-dropdown__menu-item">
              <div
                className="calendar-account-dropdown__color-indicator"
                style={{ backgroundColor: calendar.color }}
              />
              <span className="calendar-account-dropdown__calendar-name">
                {calendar.name || calendar.internalGCalendarId}
              </span>
              {calendar.calendarId === selectedCalendarId && <Check />}
            </div>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CalendarAccountDropdown;

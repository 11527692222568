import { useState } from 'react';

import { getArrayOfXDaysBackFromToday, useAuthContext } from '../data-access';
import { useSyncSleepData } from './useSyncSleepData';

const SYNCED_DAYS = 20;
const TIMEOUT_SYNC_MS = 10000; // 10 seconds

export const useInitSleepData = () => {
  const { user, updateUser } = useAuthContext();
  const { syncSleepData: syncSleepDataEndpoint } = useSyncSleepData();

  const [isSyncing, setIsSyncing] = useState(false);

  const syncedDates = getArrayOfXDaysBackFromToday(SYNCED_DAYS);

  const syncSleepData = async () => {
    if (!user.terraUserIds?.length || !user.metricId) {
      await updateUser({
        hasInitialSynced: true,
      });
      return;
    }

    setIsSyncing(true);
    try {
      const timeoutPromise = new Promise((_, reject) => {
        const timer = setTimeout(() => {
          clearTimeout(timer);
          reject(new Error('Sync operation timed out'));
        }, TIMEOUT_SYNC_MS);
      });
      await Promise.race([
        syncSleepDataEndpoint({
          terraUserIds: user.terraUserIds ?? [],
          startDate: syncedDates[0].format('YYYY-MM-DD'),
          endDate: syncedDates[syncedDates.length - 1].format('YYYY-MM-DD'),
        }),
        timeoutPromise, // Set the time out in case syncing takes infinite time
      ]);
    } finally {
      await updateUser({
        hasInitialSynced: true,
      });

      setIsSyncing(false);
    }
  };

  return {
    isSyncing,
    syncSleepData,
  };
};

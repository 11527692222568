import { MenuItem } from '@mui/material';
import { FC } from 'react';
import { Add } from '@mui/icons-material';
import clsx from 'clsx';

import './AddProjectBtn.scss';

interface AddProjectBtnProps {
  className?: string;
  onClick: () => void;
}

const AddProjectBtn: FC<AddProjectBtnProps> = ({ onClick, className }) => {
  return (
    <MenuItem className={clsx('add-project-btn', className)} onClick={onClick}>
      <Add fontSize="small" className="add-project-btn__icon" />
      <p className="add-project-btn__text">Add project</p>
    </MenuItem>
  );
};

export default AddProjectBtn;

import { FC, useMemo } from 'react';
import { Button, Snackbar } from '@mui/material';
import { upperFirst } from 'lodash';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import { taskErrorSnackBarAtom } from '../../../data-access';
import { useRecoilState } from 'recoil';
import { useStartTodoAuth } from '../../../hooks';

const TaskErrorSnackBar: FC = () => {
  const [taskErrorSnackbar, setTaskErrorSnackbar] = useRecoilState(taskErrorSnackBarAtom);

  const { handleOpenTodoAuth } = useStartTodoAuth({});

  const message = useMemo(() => {
    if (taskErrorSnackbar?.status === 401) {
      return '[Task] ' + taskErrorSnackbar.message;
    }
    return '[Task] ' + taskErrorSnackbar?.message + ' Please try again later.';
  }, [taskErrorSnackbar]);

  const handleClose = () => setTaskErrorSnackbar(null);

  return (
    <Snackbar
      open={!!taskErrorSnackbar}
      message={message}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      action={
        <>
          {taskErrorSnackbar?.status === 401 && !!taskErrorSnackbar?.provider && (
            <Button
              key={taskErrorSnackbar?.provider}
              color="error"
              size="small"
              onClick={() => handleOpenTodoAuth(taskErrorSnackbar?.provider)}
            >
              Reconnect {upperFirst(taskErrorSnackbar?.provider)}
            </Button>
          )}
          <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </>
      }
    />
  );
};

export default TaskErrorSnackBar;

import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useSubscriptionContext } from '../data-access';

const RequireSubscription = () => {
  const { hasAccessToApp } = useSubscriptionContext();

  // Redirect to the /subscribe page when no access
  if (!hasAccessToApp) {
    return <Navigate to="/subscribe" replace />;
  }

  return <Outlet />;
};

export default RequireSubscription;

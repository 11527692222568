import axios, { AxiosError } from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import dayjs from 'dayjs';

import {
  AccountApi,
  CalendarApi,
  EnergyApi,
  ErrorRes,
  ExternalApi,
  IntegrationsApi,
  MetricsApi,
  RecommendationsApi,
  SchedulerApi,
  TasksApi,
} from './openapi';

const axiosInstance = axios.create();

const API_URL = process.env.REACT_APP_API_URL;
const JWT_401_ERROR_REASON = 'jwt';

export const accountApi = new AccountApi(undefined, API_URL, axiosInstance);
export const calendarApi = new CalendarApi(undefined, API_URL, axiosInstance);
export const energyApi = new EnergyApi(undefined, API_URL, axiosInstance);
export const integrationsApi = new IntegrationsApi(undefined, API_URL, axiosInstance);
export const metricsApi = new MetricsApi(undefined, API_URL, axiosInstance);
export const recommendationsApi = new RecommendationsApi(undefined, API_URL, axiosInstance);
export const schedulerApi = new SchedulerApi(undefined, API_URL, axiosInstance);
export const tasksApi = new TasksApi(undefined, API_URL, axiosInstance);
export const externalApi = new ExternalApi(undefined, API_URL, axiosInstance);

export function getOAuthAccessToken(): string | undefined {
  return localStorage.getItem('lifestack_access_token')!;
}

export function getOAuthRefreshToken(): string | undefined {
  return localStorage.getItem('lifestack_refresh_token')!;
}

export function setOAuthRefreshToken(token: string): void {
  localStorage.setItem('lifestack_refresh_token', token);
}

export function setOAuthAccessToken(token: string): void {
  localStorage.setItem('lifestack_access_token', token);
}

axiosInstance.interceptors.request.use(
  async (config) => {
    const oAuthAccessToken = await getOAuthAccessToken();
    const userId = localStorage.getItem('lifestack_user_id');
    const newConfig = { ...config };
    newConfig.params = {
      ...newConfig.params,
      withJwt: true, // JWT flow
    };

    newConfig.headers['Content-Type'] = 'application/json';
    newConfig.headers['X-Device-Type'] = 'web';
    newConfig.headers['X-Time-Zone'] = dayjs.tz.guess();
    newConfig.headers['X-User-Id'] = userId;

    if (oAuthAccessToken) {
      newConfig.headers.Authorization = `Bearer ${oAuthAccessToken}`;
    }

    return newConfig;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const refreshAuthLogic = (failedRequest: AxiosError) => {
  const errorReason = (failedRequest.response?.data as ErrorRes)?.reason;
  if (errorReason !== JWT_401_ERROR_REASON) {
    return;
  }
  const refresh = getOAuthRefreshToken();

  // Need to create a new instance for axios to call endpoints
  const privateAccountApi = new AccountApi(undefined, API_URL, axios.create());

  return privateAccountApi
    .refreshToken(refresh!, true)
    .then(async ({ data }) => {
      if (!data) {
        setOAuthRefreshToken('');
        return;
      }
      const { access: accessToken } = data;
      setOAuthAccessToken(accessToken);

      if (failedRequest.response) {
        failedRequest.response.config.headers.Authorization = `Bearer ${accessToken}`;
      }
      return;
    })
    .catch(async (error) => {
      // Only when the refresh token is invalid (the endpoint returns 403 error), clear the refresh token
      if (error?.response?.status === 403) {
        setOAuthRefreshToken('');
        setOAuthAccessToken('');
        window.location.href = '/';
      }
    });
};

createAuthRefreshInterceptor(axiosInstance, refreshAuthLogic, {
  statusCodes: [401],
});

export * from './openapi';

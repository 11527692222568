import React, { useMemo } from 'react';
import './TimeFormatToggle.scss';
import { useGeneralSettings } from '../../../../hooks/useGeneralSettings';
import { TimeFormatType } from '@demind-inc/core';
import { CircularProgress } from '@mui/material';

const timeFormatOptions: Array<{ key: TimeFormatType; label: string }> = [
  { key: '12h', label: '12h (Am/Pm)' },
  { key: '24h', label: '24h' },
];

const TimeFormatToggle = () => {
  const {
    generalSettings: { timeFormat },
    handleGeneralSettingsChange,
    isPending,
  } = useGeneralSettings();

  const selectedTabIndex = useMemo(() => {
    return timeFormatOptions.findIndex(({ key }) => key === timeFormat);
  }, [timeFormat]);

  const handleFormatChange = (format: TimeFormatType) => {
    handleGeneralSettingsChange({ timeFormat: format });
  };

  return (
    <div className="time-format-toggle">
      <div className="time-format-toggle__title">Time Format</div>
      <div className="time-format-toggle__container">
        {isPending ? (
          <div className='time-format-toggle__loader'>
           
            <CircularProgress size={16} />
          </div>
        ) : (
          timeFormatOptions.map(({ key, label }, index) => (
            <button
              key={key}
              className={`time-format-toggle__button ${
                index === selectedTabIndex ? 'time-format-toggle__button--active' : ''
              }`}
              onClick={() => handleFormatChange(key)}
            >
              {label}
            </button>
          ))
        )}
      </div>
    </div>
  );
};

export default TimeFormatToggle;

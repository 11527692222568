import { useMutation } from '@tanstack/react-query';

import { accountApi } from '../api';
import { ReferralCode } from '@demind-inc/core';
import { useState } from 'react';
import { AxiosError } from 'axios';

interface UseValidateReferralCodeParams {
  code: string;
}

export const useValidateReferralCode = () => {
  const [error, setError] = useState<string>();

  const validateReferralMutation = useMutation({
    mutationFn: ({ code }: UseValidateReferralCodeParams) => {
      return accountApi.validateReferralCode(code).then(({ data }) => data as ReferralCode);
    },
    onMutate: () => {
      setError('');
    },
    onError: (err) => {
      const errorData = (err as AxiosError).response?.data;
      const errorMessage = (errorData as any)?.error;

      if (errorMessage) {
        setError(errorMessage);
      }
    },
  });

  return {
    validateReferralCode: validateReferralMutation.mutateAsync,
    ...validateReferralMutation,
    error,
  };
};

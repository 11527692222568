import { Construction as ConstructionIcon } from '@mui/icons-material';
import type { FC } from 'react';

import './NotSupported.scss';
import LifestackLogoName from '../../../assets/logo/logo_with_name.png';
import ScreenNotSupported from '../../../assets/common/screen_not_supported.png';
import { Button } from '@mui/material';

const NotSupported: FC = () => {
  return (
    <div className="wrapper">
      <div className="not-supported">
        <img src={LifestackLogoName} alt="Lifestack Logo" className="not-supported__logo" />
        <div className="not-supported__content">
          <img
            src={ScreenNotSupported}
            alt="Screen Not Supported"
            className="not-supported__content__image"
          />
          <div className="not-supported__content__title">Screen size not supported yet 😵</div>
          <div className="not-supported__content__desc">
            We’re sorry, but this screen size isn’t supported yet. Please try using a laptop (screen
            width {'>'} 922px) or download our mobile app instead.
          </div>
          <div className="not-supported__content__btn-group">
            <Button
              variant="contained"
              onClick={() =>
                (window.location.href =
                  'https://apps.apple.com/us/app/lifestack-calendar-and-health/id6473464429')
              }
            >
              Download iOS app
            </Button>
            <Button
              variant="contained"
              onClick={() =>
                (window.location.href =
                  'https://play.google.com/store/apps/details?id=com.demind.lifestack_calendar&hl=en_US&gl=US')
              }
            >
              Download Android app
            </Button>
          </div>
        </div>
        <div className="not-supported__footer">
          <a href={'https://lifestack.ai'} target="__blank">
            Website
          </a>
          <a href="mailto:contact@demind-inc.com" target="__blank">
            Contact Us
          </a>
        </div>
      </div>
    </div>
  );
};

export default NotSupported;

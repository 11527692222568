import { useQuery } from '@tanstack/react-query';
import { tasksApi } from '../api';
import { TaskItem } from '@demind-inc/core';

interface FetchOverdueTasksParams {
  visibleProjectIds: string[];
  dueDateTill: string;
}

export const useOverdueTasks = ({ visibleProjectIds, dueDateTill }: FetchOverdueTasksParams) => {
  return useQuery({
    queryKey: ['lifestack.todo.overdueTasks', { visibleProjectIds, dueDateTill }],
    queryFn: () =>
      tasksApi
        .getOverdueTodoTasks(visibleProjectIds, dueDateTill)
        .then(({ data }) => data as TaskItem[]),
    enabled: visibleProjectIds?.length > 0 && !!dueDateTill,
    staleTime: 15000, // 15s
  });
};

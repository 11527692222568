import { FC, useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import Stripe from 'stripe';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import clsx from 'clsx';

import './PaywallPage.scss';
import PaywallAsset from '../../../assets/onboarding/paywall.png';
import {
  OnboardingContainer,
  SubscriptionPlanContent,
  SubscriptionPlanItem,
  SubscriptionTimeline,
} from '../../../components';
import {
  basicOfferingId,
  DEFAULT_FREE_TRIAL_DAYS,
  useAuthContext,
  useAvailableSubscriptions,
  useReferralCampaign,
  useSubscriptionContext,
} from '../../../data-access';
import { translatePlanPriceDetail } from './helpers';
import { CardPayment } from './CardPayment';
import { useQueryString } from '../../../utils';
import { Button, CircularProgress } from '@mui/material';
import { getMetaDataFromStripeSubscription } from '../../../helpers';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

const PaywallPage: FC = () => {
  const [selectedPlan, setSelectedPlan] = useState<Stripe.Price | undefined>();

  const { signOut } = useAuthContext();

  const { availableSubscriptions, isLoading: isFetchingPlans } = useAvailableSubscriptions({
    provider: 'stripe',
    access: 'basic',
  }); // Web supports only Stripe payment.
  const { rewardfulCaompaignId } = useSubscriptionContext();
  const { queryString: referralQSParam } = useQueryString({ qskey: 'via' });
  const { referralCampaign } = useReferralCampaign({ campaignId: rewardfulCaompaignId });

  const handleGoback = () => {
    signOut();
    setTimeout(() => {
      window.location.href = window.location.origin;
    }, 1000);
  };

  useEffect(() => {
    if (!referralQSParam) {
      return;
    }
    // Update the URL with the referral code
    const currentUrl = new URL(window.location.href);
    currentUrl.searchParams.set('via', referralQSParam);
    window.history.pushState({}, '', currentUrl.href);

    // Reload the page to ensure to apply the referral code
    const hasReloaded = JSON.parse(localStorage.getItem('hasReloaded') || 'false') as boolean;
    if (hasReloaded) {
      return;
    }
    localStorage.setItem('hasReloaded', 'true');
    setTimeout(() => window.location.reload(), 1000);
  }, [referralQSParam]);

  const freeTrialDays = useMemo(() => {
    if (referralCampaign && referralCampaign.offer.freeTrialDays) {
      return referralCampaign.offer.freeTrialDays;
    }
    if (!selectedPlan) {
      return DEFAULT_FREE_TRIAL_DAYS;
    }
    return getMetaDataFromStripeSubscription(selectedPlan).freeTrialDays;
  }, [selectedPlan, referralCampaign]);

  const offeringId = useMemo(
    () => selectedPlan?.metadata.offeringId || basicOfferingId,
    [selectedPlan]
  );

  return (
    <div className="paywall-page">
      <OnboardingContainer
        leftChildren={
          <img src={PaywallAsset} alt="lifestack paywall" className="paywall-page__asset" />
        }
        rightChildren={
          <div className="paywall-page__right-section">
            <div className="paywall-page__content">
              <div className="paywall-page__content__title">Start your free trial</div>
              <div className="paywall-page__content__plans">
                {isFetchingPlans && (
                  <div className="paywall-page__content__plans--loader">
                    <CircularProgress />
                  </div>
                )}
                {!isFetchingPlans &&
                  availableSubscriptions.map((plan) => {
                    const recurring = plan.recurring.interval;

                    return (
                      <SubscriptionPlanItem
                        key={plan.id}
                        title={recurring === 'month' ? 'Monthly plan' : 'Annual plan'}
                        isSelected={selectedPlan?.id === plan.id}
                        isBestDeal={recurring === 'year'}
                        description={translatePlanPriceDetail(plan)}
                        onClick={() => setSelectedPlan(plan)}
                      />
                    );
                  })}
                <SubscriptionPlanContent
                  className="paywall-page__content__plans__explanation"
                  freeTrialDays={freeTrialDays}
                />

                <div className="paywall-page__content__bottom-group">
                  <SubscriptionTimeline
                    startTrialDate={dayjs().format('YYYY-MM-DD')}
                    startChargeDate={dayjs().add(freeTrialDays, 'day').format('YYYY-MM-DD')}
                    freeTrialDays={freeTrialDays}
                    price={selectedPlan ? translatePlanPriceDetail(selectedPlan, true) : ''}
                    className="paywall-page__content__timeline"
                  />
                  <Elements stripe={stripePromise}>
                    <CardPayment
                      selectedPlan={selectedPlan}
                      freeTrialDays={freeTrialDays}
                      offeringId={offeringId}
                    />
                  </Elements>
                  {!!referralCampaign && (
                    <div
                      className={clsx(
                        'paywall-page__content__referral',
                        'paywall-page__content__referral--applied'
                      )}
                    >
                      Referral code applied!
                    </div>
                  )}
                  {/* Deprecated (as of 2024 Oct 25) */}
                  {/* {appliedRefferalCode ? (
                    <div
                      className={clsx(
                        'paywall-page__content__referral',
                        'paywall-page__content__referral--applied'
                      )}
                    >
                      Referral code applied!
                    </div>
                  ) : (
                    <div
                      className="paywall-page__content__referral"
                      onClick={() => setIsReferralCodeDialogOpen(true)}
                    >
                      Have a referral code?
                    </div>
                  )} */}
                </div>
              </div>
            </div>
          </div>
        }
      />
      <Button variant="text" onClick={handleGoback} className="paywall-page__go-back">
        Go Back
      </Button>
      {/* <ReferralCodeDialog
        open={isReferralCodeDialogOpen}
        onClose={() => setIsReferralCodeDialogOpen(false)}
        onValidate={applyReferralCode}
        isValidating={isValidatingReferral}
        error={validationError}
      /> */}
    </div>
  );
};

export default PaywallPage;

import React, { useState } from 'react';
import { CircularProgress, Menu, MenuItem, Tooltip } from '@mui/material';
import { Sync, Delete } from '@mui/icons-material';

import './CalendarListMoreDropdown.scss';
import { CalendarListMoreDropdownOption } from './types';

interface CalendarListMoreDropdownProps {
  anchorEl: HTMLElement | null;
  loadingOption?: CalendarListMoreDropdownOption;
  canDelete?: boolean;
  onClickItem: (option: CalendarListMoreDropdownOption) => void;
  onClose: () => void;
}

const CalendarListMoreDropdown: React.FC<CalendarListMoreDropdownProps> = ({
  anchorEl,
  loadingOption,
  canDelete = true,
  onClickItem,
  onClose,
}) => {
  return (
    <Menu
      className="calendar-list-more-dropdown"
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}
    >
      <MenuItem
        disabled={!!loadingOption}
        onClick={() => onClickItem('sync')}
        className="calendar-list-more-dropdown__option"
      >
        {loadingOption === 'sync' && <CircularProgress size={15} />}
        {loadingOption !== 'sync' && (
          <>
            <Sync fontSize="small" />
            <p className="calendar-list-more-dropdown__option__title">Sync calendars</p>
          </>
        )}
      </MenuItem>
      <Tooltip title={canDelete ? '' : 'Calendars linked to your main account cannot be removed.'}>
        <div>
          {/* Need to wrap by div to show the tooltip */}
          <MenuItem
            disabled={!canDelete || !!loadingOption}
            onClick={() => onClickItem('delete')}
            className="calendar-list-more-dropdown__option"
          >
            {loadingOption === 'delete' && <CircularProgress size={15} />}
            {loadingOption !== 'delete' && (
              <>
                <Delete fontSize="small" />
                <p className="calendar-list-more-dropdown__option__title">Delete calendars</p>
              </>
            )}
          </MenuItem>
        </div>
      </Tooltip>
    </Menu>
  );
};

export default CalendarListMoreDropdown;

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { GeneralPreference } from '@demind-inc/core';

import { accountApi } from '../api';

interface UseUpdateGeneralPrefParams {
  preferenceId: string;
  preferences: Partial<GeneralPreference>;
}

export const useUpdateGeneralPref = () => {
  const queryClient = useQueryClient();

  const updateGeneralPrefMutation = useMutation({
    mutationFn: ({ preferenceId, preferences }: UseUpdateGeneralPrefParams) => {
      return accountApi.updatGeneralPreference(preferenceId, preferences).then((data) => data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['lifestack.preference.general'] });
    },
  });

  return {
    updateGeneralPref: updateGeneralPrefMutation.mutateAsync,
    ...updateGeneralPrefMutation,
  };
};
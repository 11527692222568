import { getCssVariable } from '../../../../../../utils';

const HIGH_PHASE_THRESHOLDING = 60;
const LOW_PHASE_THRESHOLDING = 30;
const greenBaseColor = getCssVariable('--color-green-base');
const redBaseColor = getCssVariable('--color-red-base');
const yellowBaseColor = getCssVariable('--color-yellow-base');

export const getColorByEnergyScore = (score: number) => {
  if (score >= HIGH_PHASE_THRESHOLDING) {
    return greenBaseColor;
  } else if (score >= LOW_PHASE_THRESHOLDING) {
    return yellowBaseColor;
  } else {
    return redBaseColor;
  }
};

export const getGuidanceByPeakEnergy = (targetDatePeakEnergy: number, avgPeakEnergy: number) => {
  if (targetDatePeakEnergy >= avgPeakEnergy) {
    return 'You are unstoppable today!';
  } else {
    return 'Your energy is lower than usual.';
  }
};

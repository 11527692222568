import {
  useAuthContext,
  useCalendarContext,
  visibleFeedbackDialogPostNameAtom,
} from '../../../../data-access';
import { first, unionBy } from 'lodash';
import { Button, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import './CalendarsSettings.scss';
import { getCssVariable, useFeatureFlag } from '../../../../utils';
import { Path } from '../../../../routing';

import { ReactComponent as OneDayViewIcon } from '../../../../assets/settings/calendar/1-day.svg';
import { ReactComponent as ThreeDayViewIcon } from '../../../../assets/settings/calendar/3-day.svg';
import { ReactComponent as WeekViewIcon } from '../../../../assets/settings/calendar/week.svg';
import { useRecoilState } from 'recoil';

const primaryColor = getCssVariable('--color-primary');

const CalendarsSettings = () => {
  const [_, setVisibleFeedbackDialogPostName] = useRecoilState(visibleFeedbackDialogPostNameAtom);

  const { calendarsMeta } = useCalendarContext();
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const {
    featureFlags: { FF_calendly = false },
  } = useFeatureFlag();
  const rootEmailCalendars = unionBy(calendarsMeta, 'rootEmail');

  return (
    <div className="calendars-settings">
      <p className="calendars-settings__title">Calendar</p>
      <div className="calendars-settings__grid">
        {rootEmailCalendars?.map((calendar) => (
          <div key={calendar.calendarId} className="calendars-settings__calendar-item">
            <div
              className="calendars-settings__calendar-icon"
              style={{ background: calendar.color || primaryColor }}
            >
              {first(calendar.rootEmail)}
            </div>
            {calendar.rootEmail}
          </div>
        ))}
      </div>

      <ToggleButtonGroup className="calendars-settings__view">
        <ToggleButton selected value="1-day">
          <OneDayViewIcon width={20} />
          1-day
        </ToggleButton>
        <ToggleButton
          value="3-day"
          onClick={() => setVisibleFeedbackDialogPostName('three_day_view')}
        >
          <ThreeDayViewIcon width={20} />
          3-day
        </ToggleButton>
        <ToggleButton value="week" onClick={() => setVisibleFeedbackDialogPostName('week_view')}>
          <WeekViewIcon width={20} />
          Week
        </ToggleButton>
      </ToggleButtonGroup>
      {(FF_calendly || user.access === 'internal') && (
        <Button
          className="calendars-settings__calendly"
          variant="outlined"
          onClick={() => navigate(Path.SETTINGS.CALENDLY)}
        >
          Calendly Integration
        </Button>
      )}
    </div>
  );
};

export default CalendarsSettings;

import { useEffect, useMemo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { PhaseStartEndSet } from '@demind-inc/core';
import dayjs from 'dayjs';

import {
  calculatePeakEnergy,
  getPeaksDipsBoundaries,
  isFetchingAvgCircadianAtom,
  selectedDateAtom,
  useAuthContext,
  useAvgCircadianHeatmap,
  useCircadianContext,
} from '../../data-access';
import { getHighEnergyDuration } from '../helpers';

export const useCircadianAnalysis = () => {
  const selectedDate = useRecoilValue(selectedDateAtom);
  const [_, setIsFetchingAvgCircadian] = useRecoilState(isFetchingAvgCircadianAtom);
  const { user } = useAuthContext();

  const {
    circadianRhythmsByDate,
    peaksDipsBoundariesByDate,
    peakEnergyByDate,
    isFetchingCircadian,
  } = useCircadianContext();
  const { avgCircadian, isLoading: isFetchingAvgCircadian } = useAvgCircadianHeatmap({
    userId: user.userId,
    metricId: user.metricId!,
    referenceDate: selectedDate.format('YYYY-MM-DD'),
    chronotype: user.chronotype,
  });

  useEffect(() => setIsFetchingAvgCircadian(isFetchingAvgCircadian), [isFetchingAvgCircadian]);

  const { selectedDatePeaksDipsBoundaries, selectedDateHighEnergyDuration } = useMemo(() => {
    const selectedDatePeaksDipsBoundaries = (peaksDipsBoundariesByDate.find(
      (c) => c.date === selectedDate.format('YYYY-MM-DD')
    )?.details ?? {}) as PhaseStartEndSet;
    const { highEnergyDurationHour, highEnergyDurationMin } = getHighEnergyDuration(
      selectedDatePeaksDipsBoundaries
    );

    const highEnergyDuration = `${highEnergyDurationHour}h ${highEnergyDurationMin}m`;

    return { selectedDatePeaksDipsBoundaries, selectedDateHighEnergyDuration: highEnergyDuration };
  }, [peaksDipsBoundariesByDate, selectedDate]);

  const { avgPeaksDipsBounrdaies, avgCircadianChartData, avgPeakEnergy } = useMemo(() => {
    const avgPeaksDipsBounrdaies = getPeaksDipsBoundaries(avgCircadian);
    const avgCircadianChartData = avgCircadian.filter((d) => d.value > 0) || [];
    const avgPeakEnergy = Math.round((calculatePeakEnergy(avgCircadian) ?? 0) * 100);

    return { avgPeaksDipsBounrdaies, avgCircadianChartData, avgPeakEnergy };
  }, [avgCircadian]);

  const { selectedDateCircadianChartData, selectedDateEnergyFluctuations } = useMemo(() => {
    const circadianRhythmOfTheDay = circadianRhythmsByDate.find(
      (c) => c.date === selectedDate.format('YYYY-MM-DD')
    );

    const selectedDateCircadianChartData =
      circadianRhythmOfTheDay?.values?.filter((d) => d.value > 0) || [];
    const selectedDateEnergyFluctuations = selectedDate.isAfter(dayjs(), 'day')
      ? {}
      : (circadianRhythmOfTheDay?.fluctuations ?? {});
    return { selectedDateCircadianChartData, selectedDateEnergyFluctuations };
  }, [circadianRhythmsByDate, selectedDate]);

  const selectedDatePeakEnergy = useMemo(() => {
    const peakEnergy = peakEnergyByDate.find(
      (item) => item.date === selectedDate.format('YYYY-MM-DD')
    )?.peakEnergy;
    if (peakEnergy === undefined) return 0;

    return Math.round(peakEnergy * 100);
  }, [peakEnergyByDate, selectedDate]);

  return {
    selectedDatePeakEnergy,
    selectedDatePeaksDipsBoundaries,
    selectedDateCircadianChartData,
    selectedDateHighEnergyDuration,
    selectedDateEnergyFluctuations,
    avgPeaksDipsBounrdaies,
    avgCircadianChartData,
    avgPeakEnergy,
    isFetchingSelectedCircadian: isFetchingCircadian,
    isFetchingAvgCircadian,
  };
};

import { useMemo } from 'react';
import { Dayjs } from 'dayjs';
import { isEmpty } from 'lodash';

import { useMetricsContext } from '../data-access';
import { translateSecondsToHourAndMinutes } from '../helpers';

interface UseOvernightSummaryParams {
  date: Dayjs;
}

export const useOvernightSummary = ({ date }: UseOvernightSummaryParams) => {
  const { sleepDetails, isLoading } = useMetricsContext();

  const sleepDetailsOnDay = useMemo(() => {
    return Array.isArray(sleepDetails)
      ? sleepDetails.find((item) => item.date === date.format('YYYY-MM-DD'))?.details
      : undefined;
  }, [sleepDetails]);

  const totalSleepHours = useMemo(
    () => translateSecondsToHourAndMinutes((sleepDetailsOnDay?.durationMin ?? 0) * 60),
    [sleepDetailsOnDay]
  );

  const hasNoSleepData = useMemo(() => {
    if (isLoading) {
      return false;
    }

    return !sleepDetailsOnDay || isEmpty(sleepDetailsOnDay);
  }, [sleepDetailsOnDay, isLoading]);

  return {
    isLoading,
    hasNoSleepData,
    sleepStage: sleepDetailsOnDay?.stages,
    source: sleepDetailsOnDay?.source,
    awakeTime: sleepDetailsOnDay?.awakeTime,
    sleepTime: sleepDetailsOnDay?.sleepTime,
    totalSleepHours,
  };
};

import { FC } from 'react';
import { Snackbar } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import { generalSnackBarAtom } from '../../../data-access';
import { useRecoilState } from 'recoil';

const GeneralSnackBar: FC = () => {
  const [generalSnackbar, setGeneralSnackbar] = useRecoilState(generalSnackBarAtom);

  const handleClose = () => setGeneralSnackbar(null);

  return (
    <Snackbar
      open={!!generalSnackbar}
      message={generalSnackbar}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      action={
        <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
          <CloseIcon fontSize="small" />
        </IconButton>
      }
    />
  );
};

export default GeneralSnackBar;

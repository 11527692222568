import React, { FC } from 'react';
 import './TaskLabels.scss';
import { TaskItem } from '@demind-inc/core';

interface TaskLabelsProps {
  labels?: TaskItem['labels'];
}

const TaskLabels: FC<TaskLabelsProps> = ({ labels }) => {
  if (!labels || labels.length === 0) return null;

  return (
    <div className="task-labels">
      <div className="task-labels__title">Labels</div>
      <div className="task-labels__items">
        {labels.map((label) => (
          <div key={label.name} className="task-labels__item">
            <div
              className="task-labels__item__color"
              style={{ backgroundColor: label.color || 'var(--color-gray-light)' }}
            />
            <div className="task-labels__item__name">{label.name}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TaskLabels;
import { useQuery } from '@tanstack/react-query';
import { PhaseDetailsByDate } from '@demind-inc/core';

import { metricsApi } from '../api';

interface UsePhaseParams {
  metricId: string;
  dates: string[];
}

interface UsePhasesQueryParams {
  queryKey: ['lifestack.phase.details', UsePhaseParams];
}

export const usePhases = ({ metricId, dates }: UsePhaseParams) => {
  const phasesQuery = useQuery({
    queryKey: ['lifestack.phase.details', { metricId, dates }],
    queryFn: ({ queryKey }: UsePhasesQueryParams) => {
      const [, { metricId, dates }] = queryKey;
      return metricsApi.getPhases(metricId, dates).then(({ data }) => data as PhaseDetailsByDate[]);
    },
    refetchOnWindowFocus: false,
    staleTime: 15000, // 15s
    enabled: !!metricId && !!dates.length,
  });

  return {
    phases: phasesQuery.data ?? [],
    ...phasesQuery,
  };
};

import { FC } from 'react';

import './DashboardPage.scss';

import { SideBar } from './SideBar';
import KanbanView from './KanbanView/KanbanView';
import { CalendarView } from './CalendarView';
import {
  CoreActions,
  SettingsModal,
  WearableErrorSnackBar,
  TaskErrorSnackBar,
  CalendarEventSnackBar,
} from '../../components';
import { useRecoilState } from 'recoil';
import { settingsModalAtom } from '../../data-access';
import { useFeatureFlag } from '../../utils';

const DashboardPage: FC = () => {
  const [settingsModalVisible, setSettingsModalVisible] = useRecoilState(settingsModalAtom);
  const {
    featureFlags: { FF_core_action_web = false },
  } = useFeatureFlag();

  return (
    <>
      <div className="dashboard-page">
        <div className="dashboard-page__sidebar-container">
          <SideBar className="dashboard-page__side-bar" />
        </div>
        {FF_core_action_web ? (
          <div className="dashboard-page__content">
            <CoreActions />
            <div className="dashboard-page__kanban-calendar-container">
              <KanbanView className="dashboard-page__kanban-calendar-container__kanban-view" />
              <CalendarView className="dashboard-page__kanban-calendar-container__calendar-view" />
            </div>
          </div>
        ) : (
          <>
            <KanbanView className="dashboard-page__kanban-view--no-core-actions" />
            <CalendarView className="dashboard-page__calendar-view--no-core-actions" />
          </>
        )}
      </div>
      <SettingsModal
        visible={settingsModalVisible.visible}
        onClose={() => setSettingsModalVisible({ visible: false, defaultPosition: undefined })}
        defaultPosition={settingsModalVisible.defaultPosition}
      />
      <WearableErrorSnackBar />
      <TaskErrorSnackBar />
      <CalendarEventSnackBar />
    </>
  );
};

export default DashboardPage;

import { useQuery } from '@tanstack/react-query';
import Stripe from 'stripe';
import { stripeLookupKeySet } from '@demind-inc/core';

import { accountApi } from '../api';
import { PaymentProvider } from '../types';

type LookupKeyAccess = keyof typeof stripeLookupKeySet;

interface UseAvailableSubscriptionsParams {
  provider: PaymentProvider;
  access: LookupKeyAccess;
}

interface UseAvailableSubscriptionsQueryParams {
  queryKey: ['lifestack.subscriptions.available', { provider: string; access: LookupKeyAccess }];
}

export const useAvailableSubscriptions = ({
  provider,
  access,
}: UseAvailableSubscriptionsParams) => {
  const subscriptionQuery = useQuery({
    queryKey: ['lifestack.subscriptions.available', { provider, access }],
    queryFn: ({ queryKey }: UseAvailableSubscriptionsQueryParams) => {
      const [, { provider }] = queryKey;

      return accountApi
        .getAvailableSubscriptions(access, provider)
        .then(({ data }) => data as Stripe.Price[]);
    },
    refetchOnWindowFocus: false,
    enabled: !!provider,
    gcTime: 1000 * 60 * 60 * 24 * 30, // cache time - 30 days,
    staleTime: 15000, // 15s
  });

  return {
    availableSubscriptions: subscriptionQuery.data ?? [],
    ...subscriptionQuery,
  };
};

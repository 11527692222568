export const Path = {
  DASHBOARD: '/home',
  PAYWALL: '/subscribe',
  SETTINGS: {
    CALENDLY: '/settings/calendly/configure',
  },
  CALENDLY: {
    SCHEDULE: '/calendly/schedule/:calendlyIntegId',
  },

  ONBOARDING: {
    CONNECT_DEVICES: '/onboarding/connect-devices',
    ENERGY_PREFERENCE: '/onboarding/energy-preference',
    CONNECT_TODO: '/onboarding/connect-todo',
    INITIAL_SYNC: '/onboarding/initial-sync',
  },

  CALLBACK: {
    TERRA_CALLBACK: '/callback/terra',
    TODOIST_CALLBACK: '/callback/todoist',
    TRELLO_CALLBACK: '/callback/trello',
    GOOGLE_CALLBACK: '/callback/google',
    OUTLOOK_CALLBACK: '/callback/outlook',
  },
};

import { useState } from 'react';
import { useAddTodoProject, useAuthContext } from '../data-access';
import { trackEventMixpanel } from '../utils';

export const useAddProject = () => {
  const [projectDialogVisible, setProjectDialogVisible] = useState(false);

  const { user } = useAuthContext();
  const { addTodoProject, isPending: isAddingProject } = useAddTodoProject();

  const handleProjectDialogVisible = (visible: boolean) => {
    setProjectDialogVisible(visible);
  };

  const handleAddProject = async (name: string) => {
    await addTodoProject({
      userId: user.userId,
      newProjectInfo: {
        name,
        appFrom: 'lifestack',
        closed: false,
      },
    });
    trackEventMixpanel('add_project', { appFrom: 'lifestack' });
  };

  return {
    projectDialogVisible,
    isAddingProject,
    handleAddProject,
    handleProjectDialogVisible,
  };
};

import { useQuery, useQueryClient } from '@tanstack/react-query';
import { SleepDetailsByDate } from '@demind-inc/core';
import { useCallback } from 'react';

import { metricsApi } from '../api';

interface UseSleepDetailsParams {
  userId: string;
  metricId: string;
  dates: string[];
}

interface UseSleepDetailsQueryParams {
  queryKey: ['lifestack.sleep.details', UseSleepDetailsParams];
}

export const useSleepDetails = ({ userId, metricId, dates }: UseSleepDetailsParams) => {
  const queryClient = useQueryClient();

  const sleepDetailsQuery = useQuery({
    queryKey: ['lifestack.sleep.details', { userId, metricId, dates }],
    queryFn: ({ queryKey }: UseSleepDetailsQueryParams) => {
      const [, { userId, metricId, dates }] = queryKey;
      return metricsApi
        .getSleepDetails(metricId, userId, dates)
        .then(({ data }) => data as SleepDetailsByDate[]);
    },
    refetchOnWindowFocus: false,
    enabled: !!userId && !!metricId && !!dates.length,
    staleTime: 15000, // 15s
  });

  const refetchSleepDetails = useCallback(async () => {
    await queryClient.invalidateQueries({
      queryKey: ['lifestack.sleep.details'],
    });
  }, [queryClient]);

  return {
    sleepDetails: sleepDetailsQuery.data ?? [],
    refetchSleepDetails,
    ...sleepDetailsQuery,
  };
};

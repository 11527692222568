import { useMutation, useQueryClient } from '@tanstack/react-query';

import { accountApi } from '../api';
import { FirestoreToken } from '../types';

interface UseUpdateTokenParams {
  userId: string;
  newTokenInfo: Partial<FirestoreToken>;
}

export const useUpdateToken = () => {
  const queryClient = useQueryClient();

  const updateTokenMutation = useMutation({
    mutationFn: ({ userId, newTokenInfo }: UseUpdateTokenParams) => {
      return accountApi.updateUserToken(userId, newTokenInfo).then(({ data }) => data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['lifestack.tokens'] });
    },
  });

  return {
    updateToken: updateTokenMutation.mutateAsync,
    ...updateTokenMutation,
  };
};

import { useQuery } from '@tanstack/react-query';

import { integrationsApi } from '../api';

interface UseTerraStatusParams {
  terraUserIds: string[];
}

interface UseTerraStatusQueryParams {
  queryKey: ['lifestack.terra.status', { terraUserIds: string[] }];
}

export const useTerraStatus = ({ terraUserIds }: UseTerraStatusParams) => {
  const terraStatusQuery = useQuery({
    queryKey: ['lifestack.terra.status', { terraUserIds }],
    queryFn: ({ queryKey }: UseTerraStatusQueryParams) => {
      const [, { terraUserIds }] = queryKey;

      return integrationsApi.getTerraUserStatus(terraUserIds).then(({ data }) => data);
    },
    refetchOnWindowFocus: false,
    staleTime: 15000, // 15s
    enabled: !!terraUserIds.length,
  });

  return {
    terraStatus: terraStatusQuery.data ?? [],
    ...terraStatusQuery,
  };
};

import dayjs from 'dayjs';

interface DateRange {
  startDate: dayjs.Dayjs;
  endDate: dayjs.Dayjs;
}

export const getDateRange = ({ startDate, endDate }: DateRange): dayjs.Dayjs[] => {
  const dates: dayjs.Dayjs[] = [];
  let currentDate = startDate;

  while (currentDate.isBefore(endDate) || currentDate.isSame(endDate)) {
    dates.push(currentDate);
    currentDate = currentDate.add(1, 'day');
  }

  return dates;
};

import React, { useMemo, useState } from 'react';
import clsx from 'clsx';
import { Calendar, CalendarType } from '@demind-inc/core';
import { groupBy, upperFirst } from 'lodash';
import { Error, MoreVert } from '@mui/icons-material';
import { Avatar, CircularProgress, IconButton } from '@mui/material';

import './CalendarsList.scss';
import { ManageCalendarsBtn } from './ManageCalendarsBtn';
import { CalendarListItem } from './CalendarListItem';
import { CustomTooltip } from '../../common';
import { SyncErrorTooltipContent } from './SyncErrorTooltipContent';
import {
  CalendarListMoreDropdown,
  CalendarListMoreDropdownOption,
} from './CalendarListMoreDropdown';
import { useDropdownState, useSyncCalendarLists } from '../../../hooks';
import { useAuthContext, useDeleteCalendarsInfo } from '../../../data-access';

interface CalendarsListProps {
  calendarsMeta: Calendar[];
  visibleCalendarIds: string[];
  isFetchingCalendars: boolean;
  syncErrorRootEmail?: string;
  onSelectCalendar?: (calendar: Calendar, checked: boolean) => void;
  onConnectCalendar: (calendarType: CalendarType) => void;
  className?: string;
}

interface TargetCalendarForDropdown {
  type: 'google' | 'outlook';
  rootEmail: string;
  calendarIds: string[];
}

const CalendarsList: React.FC<CalendarsListProps> = ({
  calendarsMeta,
  visibleCalendarIds,
  isFetchingCalendars,
  syncErrorRootEmail,
  onSelectCalendar = () => void 0,
  onConnectCalendar,
  className,
}) => {
  const calendarsGroupedByRoot = groupBy(calendarsMeta, 'rootEmail');
  const { anchorEl, handleCloseDropdown, handleOpenDropdown } = useDropdownState();
  const { syncCalendarsList, isSyncing } = useSyncCalendarLists();
  const [targetCalendarForDropdown, setTargetCalendarForDropdown] =
    useState<TargetCalendarForDropdown | null>(null);
  const { deleteCalendarsInfo, isPending: isDeleting } = useDeleteCalendarsInfo();
  const { user } = useAuthContext();

  const disabledCalendarType: CalendarType[] = useMemo(() => {
    const hasOutlookCalendar = calendarsMeta.some(({ calendarType }) => calendarType === 'outlook');
    return hasOutlookCalendar ? ['outlook'] : []; //#469 Support multipe outlook calendar
  }, [calendarsMeta.length]);

  const syncErrorCalendarType = useMemo(() => {
    return calendarsMeta.find(({ rootEmail }) => rootEmail === syncErrorRootEmail)?.calendarType;
  }, [syncErrorRootEmail, calendarsMeta]);

  const loadingOption: CalendarListMoreDropdownOption = useMemo(() => {
    if (isSyncing) {
      return 'sync';
    }
    if (isDeleting) {
      return 'delete';
    }
    return;
  }, [isSyncing, isDeleting]);

  const isMainClaendarForDropdown = useMemo(
    () => targetCalendarForDropdown?.rootEmail === user.email,
    [user, targetCalendarForDropdown]
  );

  const handleMoreDropdownItemClick = async (option: CalendarListMoreDropdownOption) => {
    if (!targetCalendarForDropdown) {
      handleCloseCalendarDropdown();
      return;
    }

    if (option === 'sync') {
      await syncCalendarsList({
        calendarType: targetCalendarForDropdown.type,
        rootEmail: targetCalendarForDropdown.rootEmail,
      });
    } else if (option === 'delete') {
      await deleteCalendarsInfo({
        userId: user.userId,
        targetCalendarIds: targetCalendarForDropdown.calendarIds,
      });
    }
    handleCloseCalendarDropdown();
  };

  const handleCloseCalendarDropdown = () => {
    handleCloseDropdown();
    setTargetCalendarForDropdown(null);
  };

  return (
    <div className={clsx('calendars-list', className)}>
      <div className="calendars-list__content">
        {isFetchingCalendars && <CircularProgress size={20} className="calendars-list__loading" />}
        {!isFetchingCalendars &&
          Object.entries(calendarsGroupedByRoot).map(([rootEmail, calendars]) => {
            const hasError = syncErrorRootEmail === rootEmail;
            return (
              <div className="calendars-list__item-group" key={rootEmail}>
                <div className="calendars-list__item-group__label">
                  <Avatar className="calendars-list__item-group__label__icon">
                    {upperFirst(rootEmail.charAt(0))}
                  </Avatar>
                  <span className="calendars-list__item-group__label__email">{rootEmail}</span>
                  {!hasError && (
                    <IconButton
                      aria-label="calendar-more"
                      size="small"
                      color="inherit"
                      onClick={(e) => {
                        handleOpenDropdown(e);
                        setTargetCalendarForDropdown({
                          type: calendars[0].calendarType,
                          rootEmail,
                          calendarIds: calendars.map(({ calendarId }) => calendarId),
                        });
                      }}
                    >
                      <MoreVert className="calendars-list__item-group__label__more" />
                    </IconButton>
                  )}
                  {hasError && (
                    <CustomTooltip
                      title={
                        <SyncErrorTooltipContent
                          disabled={!calendars[0]?.calendarType}
                          onReconnect={() => onConnectCalendar(calendars[0]?.calendarType)}
                        />
                      }
                    >
                      <Error className="calendars-list__item-group__label__error" />
                    </CustomTooltip>
                  )}
                </div>

                {calendars.map((calendar) => (
                  <CalendarListItem
                    key={calendar.calendarId}
                    calendar={calendar}
                    selected={visibleCalendarIds.includes(calendar.calendarId)}
                    onSelect={(checked: boolean) => onSelectCalendar(calendar, checked)}
                    className="calendars-list__item-group__item"
                  />
                ))}
              </div>
            );
          })}
      </div>
      <CalendarListMoreDropdown
        anchorEl={anchorEl}
        onClose={handleCloseCalendarDropdown}
        canDelete={!isMainClaendarForDropdown}
        loadingOption={loadingOption}
        onClickItem={handleMoreDropdownItemClick}
      />
      <ManageCalendarsBtn
        disabledCalendarType={disabledCalendarType}
        onConnect={onConnectCalendar}
        syncErrorCalendarType={syncErrorCalendarType}
      />
    </div>
  );
};

export default CalendarsList;

import { Subscription } from '@demind-inc/core';
import dayjs from 'dayjs';

import { DEFAULT_FREE_TRIAL_DAYS } from '../data-access';
import Stripe from 'stripe';

/**
 * Retrieves the number of days in the free trial period from a given subscription.
 *
 * @param {Subscription} subscription - The subscription object containing free trial information.
 * @returns {number} The number of days in the free trial period. If the subscription does not have a free trial, returns the default number of free trial days.
 */
export const getFreeTrialPeriodDaysFromLSSubscription = (subscription: Subscription) => {
  if (!subscription.freeTrial || !subscription.freeTrial) {
    return DEFAULT_FREE_TRIAL_DAYS;
  }

  return Math.abs(
    dayjs(subscription.freeTrial.endDate).diff(dayjs(subscription.freeTrial.startDate), 'day')
  );
};

/**
 * Extracts metadata from a Stripe subscription.
 *
 * @param subscription - The Stripe subscription object containing the price information.
 * @returns An object containing the free trial days and offering ID from the subscription's product metadata.
 *
 * @remarks
 * - If the `freeTrialDays` metadata is not present, a default value (`DEFAULT_FREE_TRIAL_DAYS`) is used.
 * - The `offeringId` is directly taken from the product metadata.
 */
export const getMetaDataFromStripeSubscription = (subscription: Stripe.Price) => {
  const product = subscription.product as Stripe.Product;

  const freeTrialDays = product.metadata?.freeTrialDays;
  const offeringId = product.metadata?.offeringId;

  return {
    ...product.metadata,
    freeTrialDays: parseInt(freeTrialDays) || DEFAULT_FREE_TRIAL_DAYS,
    offeringId,
  };
};

import { FC, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import dayjs from 'dayjs';

import './OutlookCallback.scss';
import {
  calendarApi,
  defaultOutlookCalScopes,
  getWeekEdgesDateOf,
  useAuthContext,
  useSyncCalendarEvents,
  useSyncOutlookCalendarsList,
} from '../../../data-access';
import { Path } from '../../../routing';

const OutlookCallback: FC = () => {
  const location = useLocation();
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const { syncOutlookCalendarsList } = useSyncOutlookCalendarsList();
  const { syncCalEvents } = useSyncCalendarEvents();

  const searchParams = new URLSearchParams(location.search);
  const code = searchParams.get('code') ?? '';

  useEffect(() => {
    if (!user.userId || !code) {
      return;
    }

    const update = async () => {
      try {
        const tokens = await calendarApi.exchangeOutlookToken(
          user.userId,
          code,
          `${window.location.origin}/callback/outlook`,
          'v2.0',
          defaultOutlookCalScopes
        );

        if (tokens) {
          const newCalendarIds = await syncOutlookCalendarsList({
            userId: user.userId,
            scopes: defaultOutlookCalScopes,
          });

          const { startDate, endDate } = getWeekEdgesDateOf(dayjs());

          syncCalEvents({
            userId: user.userId,
            calendarIds: newCalendarIds,
            startTime: startDate.toISOString(),
            endTime: endDate.toISOString(),
          });
        }
      } finally {
        navigate(Path.DASHBOARD);
      }
    };

    update();
  }, [user?.userId, code]);

  return (
    <div className="outlook-callback-page">
      <CircularProgress />
    </div>
  );
};

export default OutlookCallback;

import { FC, Suspense, useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { EventInteractionArgs } from 'react-big-calendar/lib/addons/dragAndDrop';
import { useRecoilState, useRecoilValue } from 'recoil';
import { debounce } from 'lodash';
import { CalendarTaskFrom } from '@demind-inc/core';

import './CalendarView.scss';

import { useOvernightSummary } from '../../../hooks';
import {
  eventsSnackBarAtom,
  selectedDateAtom,
  useAuthContext,
  useCalendarContext,
  useUpdateCalendarEvent,
  UseUpdateCalendarEventParams,
  useUpdateTodoTask,
} from '../../../data-access';
import {
  CALENDAR_STEP_MIN,
  CalendarTimeline,
  DateBox,
  PeakEnergy,
  RBCEvent,
  transformEventToRBCEvent,
} from '../../../components';
import { getParamToUpdateTaskTimeFromCalendar } from '../../../helpers';
import { trackEventMixpanel } from '../../../utils';
import { CircularProgress } from '@mui/material';

interface CalendarViewProps {
  className?: string;
}

const CalendarView: FC<CalendarViewProps> = ({ className }) => {
  const [events, setEvents] = useState<RBCEvent[]>([]);
  const [_, setEventsSnackbar] = useRecoilState(eventsSnackBarAtom);
  const selectedDate = useRecoilValue(selectedDateAtom);

  const { calendarEvents, findCalendarItem, checkCalendarHassAccessToModifyEvent } =
    useCalendarContext();
  const { updateCalendarEvent, status: updateCalendarEventStatus } = useUpdateCalendarEvent();
  const { user } = useAuthContext();
  const { updateTodoTask } = useUpdateTodoTask();
  const { hasNoSleepData } = useOvernightSummary({ date: selectedDate });

  useEffect(() => {
    if (updateCalendarEventStatus === 'success') {
      setEventsSnackbar('Event updated');
    } else if (updateCalendarEventStatus === 'pending') {
      setEventsSnackbar('Event updating...');
    } else if (updateCalendarEventStatus === 'error') {
      setEventsSnackbar('Failed to update event');
    }
  }, [updateCalendarEventStatus]);

  useEffect(() => {
    const transformedEvents = calendarEvents.map((e) =>
      transformEventToRBCEvent(e, findCalendarItem(e.calendarId))
    );
    setEvents(transformedEvents);
  }, [calendarEvents]);

  const debouncedUpdateCalendarEventAndTask = useCallback(
    debounce(
      (updateCalendarArgs: UseUpdateCalendarEventParams, updateTaskArgs?: CalendarTaskFrom) => {
        updateCalendarEvent(updateCalendarArgs);

        if (updateTaskArgs) {
          updateTodoTask({
            userId: updateCalendarArgs.userId,
            boardId: updateTaskArgs.boardId,
            taskId: updateTaskArgs.taskId,
            newTaskInfo: {
              ...getParamToUpdateTaskTimeFromCalendar(updateCalendarArgs.newEventOption),
              appFrom: updateTaskArgs.from,
            },
          });
        }
      },
      2000
    ),
    []
  );

  const onEventResize = (data: EventInteractionArgs<object>) => {
    const { start, end, event } = data;
    const targetEvent = event as RBCEvent;

    const hasAccesToModify = checkCalendarHassAccessToModifyEvent(targetEvent.calendarId!);

    if (!hasAccesToModify) {
      setEventsSnackbar('You do not have access to modify this event');
      return;
    }

    if (dayjs(start).isAfter(dayjs(end))) {
      return;
    }

    // Sometimes, the end time is same as start time, in that case we need to add CALENDAR_STEP_MIN to end time
    let clampedEnd = end;
    if (dayjs(start).isSame(dayjs(end))) {
      clampedEnd = dayjs(end).add(CALENDAR_STEP_MIN, 'minute').toDate();
    }

    setEvents((prev) =>
      prev.map((e) =>
        e.eventId === targetEvent.eventId ? ({ ...e, start, end: clampedEnd } as RBCEvent) : e
      )
    );

    if (!targetEvent.calendarId) {
      return;
    }

    debouncedUpdateCalendarEventAndTask(
      {
        userId: user.userId,
        calendarId: targetEvent.calendarId!,
        eventId: targetEvent.eventId,
        newEventOption: {
          start: {
            date: dayjs(start).toISOString(),
            timeZone: dayjs.tz.guess(),
          },
          end: {
            date: dayjs(clampedEnd).toISOString(),
            timeZone: dayjs.tz.guess(),
          },
        },
      },
      targetEvent.taskFrom
    );
    trackEventMixpanel('resize_calendar_event_by_drag');
  };

  const onEventDrop = (data: EventInteractionArgs<object>) => {
    const { start, end, event } = data;
    const targetEvent = event as RBCEvent;

    const hasAccesToModify = checkCalendarHassAccessToModifyEvent(targetEvent.calendarId!);

    if (!hasAccesToModify) {
      setEventsSnackbar('You do not have access to modify this event');
      return;
    }

    if (dayjs(start).isSameOrAfter(dayjs(end))) {
      return;
    }

    setEvents((prev) =>
      prev.map((e) => (e.eventId === targetEvent.eventId ? ({ ...e, start, end } as RBCEvent) : e))
    );

    if (!targetEvent.calendarId) {
      return;
    }

    debouncedUpdateCalendarEventAndTask(
      {
        userId: user.userId,
        calendarId: targetEvent.calendarId!,
        eventId: targetEvent.eventId,
        newEventOption: {
          start: {
            date: dayjs(start).toISOString(),
            timeZone: dayjs.tz.guess(),
          },
          end: {
            date: dayjs(end).toISOString(),
            timeZone: dayjs.tz.guess(),
          },
        },
      },
      targetEvent.taskFrom
    );
    trackEventMixpanel('move_calendar_event_by_drag');
  };

  return (
    <div className={clsx('calendar-view', className)}>
      <div className="calendar-view__data-header">
        <DateBox date={selectedDate} />
        <PeakEnergy hasNoSleepData={hasNoSleepData} />
      </div>

      <Suspense
        fallback={
          <div className="calendar-view__loader">
            <CircularProgress />
          </div>
        }
      >
        <CalendarTimeline
          events={events}
          hasNoSleepData={hasNoSleepData}
          onEventDrop={onEventDrop}
          onEventResize={onEventResize}
        />
      </Suspense>
    </div>
  );
};

export default CalendarView;

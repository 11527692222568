import { useMutation, useQueryClient } from '@tanstack/react-query';
import { User } from '@demind-inc/core';

import { accountApi } from '../api';
import { FirestoreUser } from '../types';

interface UseSignInParams {
  userId: string;
  newUserInfo: FirestoreUser;
  serverAuthCode?: string;
  refreshToken?: string;
  tokenExpiryDate?: string;
  accessToken: string;
  scopes?: string[];
}

export const useSignIn = () => {
  const queryClient = useQueryClient();

  const signInMutation = useMutation({
    mutationFn: ({
      userId,
      newUserInfo,
      serverAuthCode,
      refreshToken,
      accessToken,
      tokenExpiryDate,
      scopes,
    }: UseSignInParams) => {
      return accountApi
        .signIn(userId, true, {
          newUserInfo,
          serverAuthCode,
          accessToken,
          refreshToken,
          tokenExpiryDate,
          isNewTrialFlow: true,
          scopes,
        })
        .then(({ data }) => ({
          user: data.user as User,
          refreshToken: data.refreshToken as string,
          accessToken: data.accessToken as string,
        }));
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['lifestack.user'] });
      queryClient.invalidateQueries({ queryKey: ['lifestack.subscriptions'] });
    },
  });

  return {
    signIn: signInMutation.mutateAsync,
    ...signInMutation,
  };
};

import React, { FC, useEffect, useRef, useState } from 'react';
import { Modal } from '@mui/material';
import { CloseOutlined } from '@mui/icons-material';

import './SettingsModal.scss';
import { SettingsActionItem, SettingsActionList } from './SettingsActionList';
import { settingsSections } from '../constants';

interface SettingsModalProps {
  visible: boolean;
  defaultPosition?: SettingsActionItem;
  onClose: () => void;
}

const SettingsModal: FC<SettingsModalProps> = ({ visible, defaultPosition, onClose }) => {
  const [selectedAction, setSelectedAction] = useState<SettingsActionItem>('profile');
  const componentRefs = useRef(new Map<string, HTMLDivElement | null>());
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!defaultPosition) {
      return;
    }
    setTimeout(() => {
      // Scroll to the element after the modal is open
      scrollToElement(defaultPosition);
    }, 0);
  }, [defaultPosition]);

  const handleClose = () => {
    setSelectedAction('profile');
    onClose();
  };

  const scrollToElement = (key: string) => {
    const element = componentRefs.current.get(key);
    if (element && containerRef.current) {
      const containerRect = containerRef.current.getBoundingClientRect();
      const elementRect = element.getBoundingClientRect();
      containerRef.current.scrollTop += elementRect.top - containerRect.top - 20;
    }
  };

  const handleSelectAction = (action: SettingsActionItem) => {
    setSelectedAction(action);
    scrollToElement(action);
  };

  return (
    <Modal open={visible} onClose={handleClose} className="settings-modal">
      <div className="settings-modal__container">
        <div className="settings-modal__header">
          <div className="settings-modal__header__title">Settings</div>
          <CloseOutlined
            fontSize="medium"
            className="settings-modal__header__close"
            onClick={handleClose}
          />
        </div>
        <div className="settings-modal__main">
          <SettingsActionList selectedAction={selectedAction} onSelectAction={handleSelectAction} />
          <div className="settings-modal__main__content" ref={containerRef}>
            {settingsSections.map((section, index) => (
              <div key={index} className="settings-modal__main__content__section">
                <h1 className="settings-modal__main__content__section__title">{section.title}</h1>
                {section.components.map(({ Component, key }) => (
                  <div ref={(el) => componentRefs.current.set(key, el)} key={key}>
                    <Component />
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SettingsModal;

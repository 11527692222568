import { useCallback, useEffect, FC, useRef, useState } from 'react';
import { uniqueId } from 'lodash';
import { DragFromOutsideItemArgs } from 'react-big-calendar/lib/addons/dragAndDrop';
import dayjs from 'dayjs';
import { TaskItem } from '@demind-inc/core';
import { useDrop } from 'react-dnd';

import './PracticeCalendar.scss';
import { MemoizedCalendar, RBCEvent } from '../../../CalendarView';
import { useCircadianContext } from '../../../../data-access';
import { convertTaskToEvent } from '../../../../helpers';
import { dummyTasks } from '../../constants';

interface PracticeCalendarProps {
  onMoveStep: () => void;
  step: number;
  onChangeInitialDummyTasks: (tasks: TaskItem[]) => void;
  initialDummyTasks: TaskItem[];
}

const PracticeCalendar: FC<PracticeCalendarProps> = ({
  onMoveStep,
  step,
  onChangeInitialDummyTasks,
  initialDummyTasks,
}) => {
  const [draggedEvent, setDraggedEvent] = useState<Partial<RBCEvent> | null>(null);
  const [events, setEvents] = useState<RBCEvent[]>([]);
  const [draggedTask, setDraggedTask] = useState<TaskItem>();
  const scrollToTime = useRef<Date>(dayjs().toDate());
  const dragFromOutsideItem = useCallback(() => draggedEvent, [draggedEvent]);
  const hasDraggedEvent = useRef(false);

  const { findPhaseForTaskTime } = useCircadianContext();

  useEffect(() => {
    if (hasDraggedEvent.current || !draggedTask) {
      return;
    }
    setDraggedEvent({
      title: draggedTask.name,
      eventId: 'dragging',
    });
  }, [draggedTask]);

  const handleDropEventFromOutside = (data: DragFromOutsideItemArgs) => {
    const eventFromTask = draggedTask ? convertTaskToEvent(draggedTask) : {};
    const phase = findPhaseForTaskTime(data.start.toString(), data.end.toString());

    if (step === 1 && (phase !== 'morning_high' || draggedTask.taskId !== 'task-1')) return;
    if (step === 2 && (phase !== 'morning_low' || draggedTask.taskId !== 'task-2')) return;

    const newEventRBC = {
      ...eventFromTask,
      start: new Date(dayjs(data.start).toISOString()),
      end: new Date(dayjs(data.end).toISOString()),
      title: draggedTask?.name,
      eventId: uniqueId('event-'),
      color: '#5A76AA',
      calendarId: uniqueId(),
    } as RBCEvent;

    scrollToTime.current = dayjs(data.start).subtract(10, 'minutes').toDate();

    let tasksWithDateTime = initialDummyTasks.map((task) => {
      if (task.taskId === draggedTask.taskId) {
        return {
          ...task,
          startDateTime: {
            ...task?.startDateTime,
            datetime: dayjs(data.start).format('YYYY-MM-DD HH:mm'),
          },
          dueDateTime: {
            ...task?.dueDateTime,
            datetime: dayjs(data.end).format('YYYY-MM-DD HH:mm'),
          },
        };
      }
      return task;
    });

    if (step === 1) {
      tasksWithDateTime = [...tasksWithDateTime, dummyTasks[1]];
    }

    onChangeInitialDummyTasks(tasksWithDateTime);
    setEvents((prev) => [...prev, newEventRBC]);
    onMoveStep();
  };

  const [_, dropTaskOnCalendar] = useDrop(() => ({
    accept: 'TASK',
    hover: (item: TaskItem) => setDraggedTask(item),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
    drop: () => setDraggedTask(undefined),
  }));

  return (
    <div ref={dropTaskOnCalendar} className="dummy-calendar">
      <MemoizedCalendar
        ref={dropTaskOnCalendar}
        events={events}
        hasNoSleepData={true}
        onEventDrop={() => {}}
        onEventResize={() => {}}
        onSelectEvent={() => {}}
        onDropEventFromOutside={handleDropEventFromOutside}
        onSelectSlot={() => {}}
        dragFromOutsideItem={dragFromOutsideItem}
        scrollToTime={scrollToTime.current}
      />
    </div>
  );
};

export default PracticeCalendar;

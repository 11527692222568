import { useMutation } from '@tanstack/react-query';
import { ConvertedReferralCampaign } from '@demind-inc/core';

import { accountApi } from '../api';

interface UseRequestStripeSubscriptionParams {
  userId: string;
  priceId: string;
  email: string;
  customerToken: string;
  offeringId: string;
  freeTrialDays?: number;
  referral?: ConvertedReferralCampaign;
}

export function useRequestStripeSubscription() {
  const requestSubscription = useMutation({
    mutationFn: ({
      userId,
      priceId,
      email,
      customerToken,
      offeringId,
      freeTrialDays,
      referral,
    }: UseRequestStripeSubscriptionParams) => {
      return accountApi.requestStripeSubscription(userId, {
        priceId,
        email,
        customerToken,
        offeringId,
        freeTrialDays,
        referral,
      });
    },
  });

  return {
    requestStripeSubscription: requestSubscription.mutateAsync,
    ...requestSubscription,
  };
}

import mixpanel from 'mixpanel-browser';

import { isProduction } from '../environment';
import { User } from '@demind-inc/core';

export const initMixpanel = () => {
  if (!process.env.REACT_APP_MIXPANEL_TOKEN || !isProduction) {
    return;
  }

  mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {
    debug: false,
    track_pageview: true,
    persistence: 'localStorage',
    ignore_dnt: true,
  });
};

export const setUserProperties = async (user: User) => {
  if (!isProduction) {
    return;
  }
  await mixpanel.identify(user.userId);

  const targetSubscription = user.subscriptions?.find(({ status }) =>
    ['active', 'freeTrial', 'unsubscribed'].includes(status)
  );

  mixpanel.people.set({
    $email: user.email,
    access: user.access,
    ...(targetSubscription
      ? {
          plan: targetSubscription.recurring!,
          status: targetSubscription.status!,
          unsubscribed_date: targetSubscription.unsubscribedDate!,
          expiration_date: targetSubscription.expirationDate!,
          freetrial_start_date: targetSubscription.freeTrial?.startDate!,
          freetrial_end_date: targetSubscription.freeTrial?.endDate!,
          referral: targetSubscription.referral?.campaignId!,
          offering_id: targetSubscription.offeringId!,
          product_id: targetSubscription.productId!,
        }
      : {}),
  });
};

export const trackEventMixpanel = (action: string, params?: object) => {
  if (!isProduction) {
    return;
  }
  mixpanel.track(action, {
    platform: 'web',
    ...params,
  });
};

import { FC, JSXElementConstructor, ReactElement, useMemo, useState } from 'react';
import { Menu, MenuItem } from '@mui/material';
import clsx from 'clsx';

import './TaskDetailTimeProject.scss';
import { TaskDetail } from '../TaskDetailModal';
import { AddProjectBtn } from '../../Project';

interface TaskDetailTimeProjectProps {
  taskTime: string;
  phase: {
    color?: string;
    label?: string;
    labelIcon?: ReactElement<any, string | JSXElementConstructor<any>>;
    explanation?: string;
  } | null;
  taskInfo: TaskDetail;
  todoProjects: { todoTaskId: string; name: string }[];
  isDisabled: boolean;
  findProject: (boardId: string) => { name: string } | undefined;
  onProjectSelect: (boardId: string) => void;
  onProjectAdd: () => void;
}

const TaskDetailTimeProject: FC<TaskDetailTimeProjectProps> = ({
  taskTime,
  phase,
  taskInfo,
  findProject,
  todoProjects,
  onProjectSelect,
  onProjectAdd,
  isDisabled,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { projectName, hasSelectedProject } = useMemo(() => {
    const targetProject = findProject(taskInfo.boardId);
    const hasSelectedProject = !!targetProject;
    const projectName = targetProject?.name ? `# ${targetProject?.name}` : 'Select project';

    return { projectName, hasSelectedProject };
  }, [findProject, taskInfo]);

  const handleMenuOpen = (e: HTMLElement) => {
    if (isDisabled) {
      return;
    }
    setAnchorEl(e);
  };

  return (
    <div className="task-time-project">
      <div className="task-time-project__time-group">
        {taskTime && (
          <div
            className="task-time-project__time-group__time"
            style={{ borderColor: phase?.color, color: phase?.color }}
          >
            {taskTime}
          </div>
        )}
        {phase?.label && (
          <div
            className="task-time-project__time-group__phase"
            style={{ backgroundColor: phase.color }}
          >
            {phase.label}
          </div>
        )}
      </div>
      <div
        className={clsx(
          'task-time-project__project',
          isDisabled && 'task-time-project__project--disabled',
          !hasSelectedProject && 'task-time-project__project--empty',
          anchorEl && 'task-time-project__project--open'
        )}
        onClick={(e) => handleMenuOpen(e.currentTarget)}
      >
        {projectName}
      </div>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        className="task-time-project__project-dropdown"
      >
        {todoProjects.map((project) => (
          <MenuItem
            key={project.todoTaskId}
            onClick={() => {
              onProjectSelect(project.todoTaskId);
              setAnchorEl(null);
            }}
            value={project.todoTaskId}
            disabled={isDisabled}
            className={clsx('task-time-project__project-dropdown__item', {
              'task-time-project__project-dropdown__item--selected':
                project.todoTaskId === taskInfo.boardId,
            })}
          >
            {project.name}
          </MenuItem>
        ))}
        <AddProjectBtn onClick={onProjectAdd} />
      </Menu>
    </div>
  );
};

export default TaskDetailTimeProject;

import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { Status, useAuthContext } from '../data-access';

const RequireAuth = () => {
  const location = useLocation();
  const { status } = useAuthContext();

  // Redirect to the requested path after successful login.
  if (status === Status.UN_AUTHORIZED) {
    return <Navigate to="/" state={{ redirectTo: location.pathname }} replace />;
  }

  return <Outlet />;
};

export default RequireAuth;

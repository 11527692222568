import { FC } from 'react';
import clsx from 'clsx';

import './SubscriptionTimeline.scss';
import { DEFAULT_FREE_TRIAL_DAYS } from '../../../data-access';

interface SubscriptionTimelineProps {
  startTrialDate: string;
  startChargeDate: string;
  freeTrialDays?: number;
  price?: string;
  className?: string;
}

const SubscriptionTimeline: FC<SubscriptionTimelineProps> = ({
  startChargeDate,
  startTrialDate,
  freeTrialDays = DEFAULT_FREE_TRIAL_DAYS,
  price = '',
  className,
}) => {
  return (
    <div className={clsx('subscription-timeline', className)}>
      <div className="subscription-timeline__left-group">
        <div className={clsx('subscription-timeline__vertical-line', 'vertical-line')}>
          <div className="vertical-line__circle" />
          <div className="vertical-line__line" /> <div className="vertical-line__circle" />
        </div>
        <div className="subscription-timeline__title-group">
          <div className="subscription-timeline__title-group__action">
            Due {startTrialDate} (Cancel Anytime)
          </div>
          <div className="subscription-timeline__title-group__action">Due {startChargeDate}</div>
        </div>
      </div>
      <div className="subscription-timeline__right-group">
        <div className="subscription-timeline__price">
          {freeTrialDays} days free <span className="subscription-timeline__price--red"> $0</span>
        </div>
        <div className="subscription-timeline__price">{price}</div>
      </div>
    </div>
  );
};

export default SubscriptionTimeline;

import { TodoIntegrationType } from '@demind-inc/core';

export const AVAILABLE_TODO_INTEGRATIONS_TYPE: TodoIntegrationType[] = [
  'todoist',
  'trello',
  'ticktick',
];

export const DEFAULT_FREE_TRIAL_DAYS = 14;

export const basicOfferingId = 'basic (with free trial)';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { calendarApi } from '../api';

interface UseSyncOutlookCalendarsListParams {
  userId: string;
  scopes?: string[];
}

export function useSyncOutlookCalendarsList() {
  const queryClient = useQueryClient();

  const syncOutlookCalendarsListMutation = useMutation({
    mutationFn: ({ userId, scopes }: UseSyncOutlookCalendarsListParams) => {
      return calendarApi
        .syncOutlookCalendarListV2(userId, { version: 'v2.0', scopes })
        .then(({ data }) => (data ?? []) as string[]);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['lifestack.calendar.meta'] });
      queryClient.invalidateQueries({ queryKey: ['lifestack.calendar.events'] });
    },
  });

  return {
    syncOutlookCalendarsList: syncOutlookCalendarsListMutation.mutateAsync,
    ...syncOutlookCalendarsListMutation,
  };
}

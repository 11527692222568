import React from 'react';
import { Menu, MenuItem, CircularProgress, IconButton, Dialog } from '@mui/material';
import { ALL_TODO_INTEGRATIONS_TYPE, TodoIntegrationType } from '@demind-inc/core';
import './ConnectTodosDropdown.scss';
import {
  AVAILABLE_TODO_INTEGRATIONS_TYPE,
  CannyPostOptionnName,
  visibleFeedbackDialogPostNameAtom,
} from '../../../data-access';
import { TODO_INTEGRATION_ICONS } from '../constants';
import { Close, Check, Add, OpenInFull } from '@mui/icons-material';
import clsx from 'clsx';
import { useRecoilState } from 'recoil';

interface ConnectTodosDropdownProps {
  anchorEl: HTMLElement | null;
  generatingTodoAuth?: TodoIntegrationType;
  onClickItem: (todoType: TodoIntegrationType) => void;
  onClose: () => void;
  connectedTodos?: Array<{ source: TodoIntegrationType }>;
}

const ConnectTodosDropdown: React.FC<ConnectTodosDropdownProps> = ({
  anchorEl,
  generatingTodoAuth,
  onClickItem,
  onClose,
  connectedTodos,
}) => {
  const [_, setVisibleFeedbackPostName] = useRecoilState(visibleFeedbackDialogPostNameAtom);

  const isConnected = (todoType: TodoIntegrationType) =>
    connectedTodos?.some(({ source }) => source === todoType);
  const isComingSonn = (todoType: TodoIntegrationType) =>
    !AVAILABLE_TODO_INTEGRATIONS_TYPE.includes(todoType);

  const renderMenuItemContent = (todoType: TodoIntegrationType) => (
    <>
      <div className="connect-todos-dropdown__item__text">
        {isComingSonn(todoType) && (
          <>
            <OpenInFull fontSize="inherit" />
            Coming soon
          </>
        )}
        {!isComingSonn(todoType) && isConnected(todoType) && (
          <>
            <Check fontSize="inherit" />
            Connected
          </>
        )}
        {!isComingSonn(todoType) && !isConnected(todoType) && (
          <>
            <Add fontSize="inherit" />
            Connect
          </>
        )}
      </div>
      {generatingTodoAuth === todoType ? (
        <CircularProgress size={15} />
      ) : (
        <img
          src={TODO_INTEGRATION_ICONS[todoType]}
          alt={todoType}
          className="connect-todos-dropdown__icon"
        />
      )}
    </>
  );

  const handleClickItem = (todoType: TodoIntegrationType) => {
    if (isComingSonn(todoType)) {
      const postName = {
        todoist: null,
        trello: null,
        ticktick: 'support_ticktick',
        notion: 'support_notion',
      }[todoType];
      setVisibleFeedbackPostName(postName as CannyPostOptionnName | null);
      return;
    }
    onClickItem(todoType);
  };

  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}
      className="connect-todos-dropdown"
    >
      <div className="connect-todos-dropdown__header">
        <p>Connect Todos</p>
        <IconButton onClick={onClose} className="connect-todos-dropdown__close-button">
          <Close fontSize="small" className="connect-todos-dropdown__close-button__icon" />
        </IconButton>
      </div>
      <div className="connect-todos-dropdown__content">
        {ALL_TODO_INTEGRATIONS_TYPE.map((todoType) => {
          const isDisabled = !!generatingTodoAuth || isConnected(todoType);

          return (
            <MenuItem
              key={todoType}
              onClick={() => handleClickItem(todoType)}
              disabled={isDisabled}
              className={clsx('connect-todos-dropdown__item', {
                'connect-todos-dropdown__item--connected': isConnected(todoType),
              })}
            >
              {renderMenuItemContent(todoType)}
            </MenuItem>
          );
        })}
      </div>
    </Menu>
  );
};

export default ConnectTodosDropdown;

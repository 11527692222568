import { FC } from 'react';
import { Snackbar } from '@mui/material';
import { useRecoilState } from 'recoil';

import { eventsSnackBarAtom } from '../../../data-access';

const CalendarEventSnackBar: FC = () => {
  const [eventsSnackbar, setEventsSnackbar] = useRecoilState(eventsSnackBarAtom);

  return (
    <Snackbar
      open={!!eventsSnackbar}
      autoHideDuration={1500}
      onClose={(_, reason) => reason === 'timeout' && setEventsSnackbar('')}
      message={eventsSnackbar}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    />
  );
};

export default CalendarEventSnackBar;

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { calendarApi } from '../api';

interface UseDeleteCalendarInfoarams {
  userId: string;
  targetCalendarIds: string[];
}

export const useDeleteCalendarsInfo = () => {
  const queryClient = useQueryClient();

  const deleteCalendarInfoMutation = useMutation({
    mutationFn: ({ userId, targetCalendarIds }: UseDeleteCalendarInfoarams) => {
      return calendarApi
        .removeCalendarsInfo(userId, { calendarIds: targetCalendarIds })
        .then((data) => data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['lifestack.calendar.meta'] });
    },
  });

  return {
    deleteCalendarsInfo: deleteCalendarInfoMutation.mutateAsync,
    ...deleteCalendarInfoMutation,
  };
};

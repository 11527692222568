declare global {
  interface Window {
    Rewardful: {
      referral?: string | null;
      campaign?: {
        id: string;
        name: string;
      };
      [key: string]: any;
    };
  }
}

export const getRewardfulCustomerId = () => {
  return window.Rewardful?.referral!;
};

export const getRewardfulCampaign = () => {
  return window.Rewardful?.campaign;
};

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { tasksApi } from '../api';
import { AddTodoTaskBoardProps } from '../types';

interface UseAddTodoProjectParams {
  userId: string;
  newProjectInfo: AddTodoTaskBoardProps;
}

export const useAddTodoProject = () => {
  const queryClient = useQueryClient();

  const addProjectMutation = useMutation({
    mutationFn: ({ userId, newProjectInfo }: UseAddTodoProjectParams) => {
      return tasksApi.addTodoProject(userId, newProjectInfo).then(({ data }) => data.taskProjectId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['lifestack.todo.projects'] });
      queryClient.invalidateQueries({ queryKey: ['lifestack.user'] });
    },
  });

  return {
    addTodoProject: addProjectMutation.mutateAsync,
    ...addProjectMutation,
  };
};

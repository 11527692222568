import React, { FC, useMemo, useState } from 'react';
import clsx from 'clsx';
import { AccessTime } from '@mui/icons-material';
import { TimePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';

import './EventTimeSelector.scss';
import { useGeneralSettings } from '../../../../hooks/useGeneralSettings';

interface EventTimeSelectorProps {
  startDateTime: Dayjs;
  endDateTime: Dayjs;
  onStartTimeChange: (date: Dayjs) => void;
  onEndTimeChange: (date: Dayjs) => void;
  className?: string;
}

type OpenPicker = 'start' | 'end' | null;

const EventTimeSelector: FC<EventTimeSelectorProps> = ({
  startDateTime,
  endDateTime,
  onStartTimeChange,
  onEndTimeChange,
  className,
}) => {
  const [openPicker, setOpenPicker] = useState<OpenPicker>(null);
  const {
    generalSettings: { timeFormat },
  } = useGeneralSettings();
  const datePickerTimeFormat = useMemo(() => {
    return timeFormat === '12h' ? 'h:mm A' : timeFormat === '24h' ? 'H:mm' : 'h:mm A';
  }, [timeFormat]);

  const renderTimePicker = (
    dateTime: Dayjs,
    onChange: (date: Dayjs) => void,
    pickerType: 'start' | 'end'
  ) => (
    <div className="event-time-selector__time">
      <p>{dayjs(dateTime).format('ddd, MMM DD')}</p>
      <TimePicker
        value={dateTime}
        ampm={timeFormat === '12h'}
        onChange={(val) => val && onChange(val)}
        open={openPicker === pickerType}
        onClose={() => setOpenPicker(null)}
        slotProps={{
          openPickerIcon: { style: { display: 'none' } },
          textField: {
            variant: 'standard',
            onClick: () => setOpenPicker(pickerType),
          },
        }}
        className="event-time-selector__time-picker"
        format={datePickerTimeFormat}
      />
    </div>
  );

  return (
    <div className={clsx('event-time-selector', className)}>
      <div className="event-time-selector__icon-container">
        <AccessTime className="event-time-selector__icon" fontSize="medium" />
      </div>
      <div className="event-time-selector__container">
        {renderTimePicker(startDateTime, onStartTimeChange, 'start')}
        {renderTimePicker(endDateTime, onEndTimeChange, 'end')}
      </div>
    </div>
  );
};

export default EventTimeSelector;

import { useState } from 'react';
import { Action, ACTIONS } from './constants';
import './CoreActions.scss';
import { CoreActionModal } from './CoreActionModal';

const CoreActions = () => {
  const [selectedModal, setSelectedModal] = useState<Action | null>(null);
  return (
    <div className="core-actions">
      <CoreActionModal setSelectedModal={setSelectedModal} selectedModal={selectedModal} />
      {ACTIONS.map((action) => (
        <div
          key={action.title}
          className="core-actions__action"
          onClick={() => setSelectedModal(action)}
        >
          <p className="core-actions__action__title">{action.title}</p>
          <p className="core-actions__action__description">{action.description}</p>
        </div>
      ))}
    </div>
  );
};

export default CoreActions;

import { CircadianPhase, HeatmapDataType, PhaseStartEndSet } from '@demind-inc/core';
import { groupBy, max, sumBy } from 'lodash';
import dayjs from 'dayjs';

import { PeakDipStartEndSet } from '../types';
const highEnergyPhage: CircadianPhase[] = ['evening_high', 'morning_high'];
/**
 * Calculates the peak energy from an array of HeatmapDataType values.
 *
 * @param values - An optional array of HeatmapDataType values.
 * @returns The maximum value from the array of HeatmapDataType values.
 */
export const calculatePeakEnergy = (values?: HeatmapDataType[]) => {
  if (!values) {
    return;
  }
  return max(values.map(({ value }) => value));
};

/**
 * Retrieves the start and end timestamps for each circadian phase in the given heatmap data.
 *
 * @param circadian - The heatmap data containing circadian phases.
 * @returns An object containing the start and end timestamps for each circadian phase.
 */
export const getCircdaianPhaseBoundaries = (circadian: HeatmapDataType[]): PhaseStartEndSet => {
  const phaseTimestamp: PhaseStartEndSet = {
    wakeup_low: { start: '', end: '' },
    wakeup_normal: { start: '', end: '' },
    morning_high: { start: '', end: '' },
    morning_normal: { start: '', end: '' },
    morning_low: { start: '', end: '' },
    evening_high: { start: '', end: '' },
    evening_low: { start: '', end: '' },
    evening_normal: { start: '', end: '' },
  };

  const groupedByPhase = groupBy(circadian, 'phase');
  Object.entries(groupedByPhase).forEach(([phase, data]) => {
    const start = data[0].y;
    const end = data[data.length - 1].y;
    phaseTimestamp[phase as keyof PhaseStartEndSet] = {
      start: dayjs(start).toISOString(),
      end: dayjs(end).toISOString(),
    };
  });

  return phaseTimestamp;
};

/**
 * Retrieves the boundaries for each peak and dip phase from the given circadian data.
 *
 * @param circadian - The circadian data containing heatmap information.
 * @returns An object containing the start and end boundaries for each peak and dip phase.
 */
export const getPeaksDipsBoundaries = (circadian: HeatmapDataType[]): PeakDipStartEndSet => {
  const allPhaseBoundaries = getCircdaianPhaseBoundaries(circadian);

  const peakDipBoundaries = {
    wakeup_low: {
      start: allPhaseBoundaries.wakeup_low.start,
      end: allPhaseBoundaries.wakeup_low.end,
    },
    morning_high: {
      start: allPhaseBoundaries.morning_high.start,
      end: allPhaseBoundaries.morning_high.end,
    },
    morning_low: {
      start: allPhaseBoundaries.morning_low.start,
      end: allPhaseBoundaries.morning_low.end,
    },
    evening_high: {
      start: allPhaseBoundaries.evening_high.start,
      end: allPhaseBoundaries.evening_high.end,
    },
    evening_low: {
      start: allPhaseBoundaries.evening_low.start,
      end: allPhaseBoundaries.evening_low.end,
    },
  };

  return peakDipBoundaries;
};

export const getHighEnergyDuration = (phase: PhaseStartEndSet) => {
  const targetPhases = Object.entries(phase).filter(([key, _]) => {
    return highEnergyPhage.includes(key as CircadianPhase);
  });

  const sumDuration = sumBy(targetPhases, ([_, value]) => {
    return value?.start && value?.end
      ? Math.abs(dayjs(value.end).diff(dayjs(value.start), 'minutes'))
      : 0;
  });

  const highEnergyDurationHour = Math.floor(sumDuration / 60);
  const highEnergyDurationMin = sumDuration % 60;

  return { highEnergyDurationHour, highEnergyDurationMin };
};

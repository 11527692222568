import dayjs, { Dayjs } from 'dayjs';

/**
 * Calculates the start and end time of multiple days.
 * @param startDate - The start date.
 * @param endDate - The end date.
 * @returns An object containing the start and end time.
 */
export const getStartEndTimeOfMultDays = (startDate: Dayjs, endDate: Dayjs) => {
  const startTime = startDate
    .set('hours', 0)
    .set('minutes', 0)
    .set('seconds', 0)
    .set('milliseconds', 0);
  const endTime = endDate
    .add(1, 'day')
    .set('hours', 0)
    .set('minutes', 0)
    .set('seconds', 0)
    .set('milliseconds', 0);

  return { startTime, endTime };
};

/**
 * Returns an array of dates, starting from today and going back in time for the specified duration.
 * @param duration The number of days to go back in time.
 * @returns An array of dates in descending order.
 */
export const getArrayOfXDaysBackFromToday = (duration: number) => {
  const arrayOfDates = Array.from({ length: duration })
    .map((_, i) => dayjs().subtract(i, 'day'))
    .sort((a, b) => (a.isAfter(b) ? 1 : -1));

  return arrayOfDates;
};

/**
 * Returns an array of dates, starting from today and going forward in time for the specified duration.
 * @param duration The number of days to go back in time.
 * @returns An array of dates in descending order.
 */
export const getArrayOfXDaysForwardFromToday = (duration: number) => {
  const arrayOfDates = Array.from({ length: duration })
    .map((_, i) => dayjs().add(i, 'day'))
    .sort((a, b) => (a.isAfter(b) ? 1 : -1));

  return arrayOfDates;
};

/**
 * Returns an array of dates representing the week containing the given date.
 * The week starts on Sunday and ends on Saturday.
 *
 * @param date - The date for which to get the week dates.
 * @returns An array of dates representing the week.
 */
export const getWeekDatesOf = (date: Dayjs) => {
  const startDate = date
    .set('hours', 0)
    .set('minutes', 0)
    .set('seconds', 0)
    .set('milliseconds', 0)
    .weekday(0);

  return Array.from({ length: 7 }).map((_, i) => {
    return startDate.add(i, 'day');
  });
};
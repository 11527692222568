import React, { useMemo } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import clsx from 'clsx';
import { TaskItem } from '@demind-inc/core';
import { useRecoilState } from 'recoil';
import { useDrop } from 'react-dnd';

import './KanbanList.scss';
import { KanbanTaskCard } from '../KanbanTaskCard';
import {
  eventsSnackBarAtom,
  useAuthContext,
  useCircadianContext,
  useTodoTasksContext,
  useUpdateTaskStatus,
  visibleTaskDetailAtom,
} from '../../../data-access';
import { CircularProgress } from '@mui/material';
import { trackEventMixpanel } from '../../../utils';
import { AddOutlined } from '@mui/icons-material';

interface KanbanListProps {
  date: Dayjs;
  tasks: TaskItem[];
  isFetchingTasks?: boolean;
  onMoveTask: (task: TaskItem) => void;
  className?: string;
  isOverDue?: boolean;
  isDemo?: boolean;
}

const DEMO_TASK_PROJECT_NAME = 'Biz';

const KanbanList: React.FC<KanbanListProps> = ({
  date,
  tasks,
  isFetchingTasks = false,
  onMoveTask,
  className,
  isOverDue,
  isDemo,
}) => {
  const { findProject, todoProjects } = useTodoTasksContext();
  const [__, setVisibleTask] = useRecoilState(visibleTaskDetailAtom);
  const { findPhaseForTaskTime } = useCircadianContext();
  const { user } = useAuthContext();
  const { updateTodoTaskStatus } = useUpdateTaskStatus();
  const [_, setEventsSnackbar] = useRecoilState(eventsSnackBarAtom);

  const hasNoProjects = !todoProjects.length;

  const sortedTasks = useMemo(
    () =>
      tasks?.sort((a, b) => {
        // Sort tasks by completed status
        if (a.completed && !b.completed) return 1;
        if (!a.completed && b.completed) return -1;
        return 0;
      }),
    [tasks]
  );

  const [{ isOver, canDrop }, drop] = useDrop(
    () => ({
      accept: 'TASK',
      drop: onMoveTask,
      canDrop: (item) => {
        if (isOverDue) return false;
        return (
          dayjs(item?.dueDateTime?.datetime).format('YYYY-MM-DD') !== date?.format('YYYY-MM-DD')
        );
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    [onMoveTask]
  );

  const handleTaskComplete = async (taskId: string, boardId: string, completed: boolean) => {
    try {
      await updateTodoTaskStatus({
        taskId,
        boardId,
        completed,
        userId: user.userId,
      });
      setEventsSnackbar(`Task ${completed ? 'completed' : 'incompleted'}`);
    } catch (error) {
      setEventsSnackbar('Failed to update task status');
    }
  };

  return (
    <div ref={drop} className={clsx('kanban-list', className)}>
      <div className="kanban-list__container">
        <div className="kanban-list__header">
          <div className="kanban-list__header__day">
            {isOverDue ? 'Overdue' : date.format('dddd')}
          </div>
          <div className="kanban-list__header__date">
            {isOverDue && '- '}
            {date.format('MMM DD')}
          </div>
        </div>
        <div className="kanban-list__item-group">
          {isFetchingTasks && <CircularProgress className="kanban-list__loading" size={30} />}
          {!isFetchingTasks &&
            (isDemo || !hasNoProjects) &&
            sortedTasks?.map((task) => (
              <KanbanTaskCard
                key={task.taskId}
                task={task}
                phase={
                  task.startDateTime?.datetime && task.dueDateTime?.datetime
                    ? findPhaseForTaskTime(task.startDateTime.datetime, task.dueDateTime.datetime)
                    : undefined
                }
                isOverDue={isOverDue}
                projectName={isDemo ? DEMO_TASK_PROJECT_NAME : findProject(task.boardId!)?.name}
                onClick={() => {
                  if (isDemo) return;
                  setVisibleTask(task);
                  trackEventMixpanel('open_task_detail_modal', {
                    taskId: task.taskId,
                  });
                }}
                onTaskComplete={(completed) =>
                  handleTaskComplete(task.taskId, task.boardId!, completed)
                }
              />
            ))}
          {isOver && canDrop && <div className="kanban-list__preview-card" />}
          {!isFetchingTasks && !isOverDue && (
            <button
              className="kanban-list__add-task"
              onClick={() =>
                setVisibleTask({
                  dueDateTime: {
                    date: dayjs(date).format('YYYY-MM-DD'),
                    timezone: dayjs.tz.guess(),
                  },
                } as TaskItem)
              }
              disabled={isDemo}
            >
              <AddOutlined className="kanban-list__add-task__icon" />
              <p className="kanban-list__add-task__text">Add task</p>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default KanbanList;

import React from 'react';
import {
  InputLabel,
  Box,
  MenuItem,
  FormControl,
  Select,
  SelectChangeEvent,
  Chip,
  Input,
} from '@mui/material';
import clsx from 'clsx';
import SellIcon from '@mui/icons-material/Sell';
import { defaultEventCategorySet, EventCategorySet } from '@demind-inc/core';

import './EventCategories.scss';
import { ReactComponent as RobotIcon } from '../../../../assets/scheduler/robot.svg';
import { getCssVariable } from '../../../../utils';

interface EventCategoriesProps {
  className?: string;
  selectedCategories: EventCategorySet[];
  setSelectedCategories: (selectedCategories: EventCategorySet[]) => void;
}

const robotIconColor = getCssVariable('--color-primary');

export default function EventCategories({
  className,
  selectedCategories,
  setSelectedCategories,
}: EventCategoriesProps) {
  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const selectedIds = event.target.value as string[];
    const selectedCategorySets = defaultEventCategorySet
      .filter((category) => selectedIds.includes(category.id))
      .map((category) => ({ ...category, predicted: false }));
    setSelectedCategories(selectedCategorySets);
  };

  return (
    <div className={clsx('event-categories', className)}>
      <FormControl className="event-categories__form-control">
        <InputLabel id="multiple-category-label" className="event-categories__label">
          <div className='event-categories__label-icon'>
            <SellIcon fontSize="medium" />
          </div>
          <span className="event-categories__label-text">Select categories</span>
        </InputLabel>
        <Select
          labelId="multiple-category-label"
          id="multiple-category-select"
          multiple
          value={selectedCategories?.map((category) => category.id)}
          disableUnderline
          onChange={handleChange}
          input={<Input id="select-multiple-category" />}
          renderValue={(selected) => (
            <Box className="event-categories__box">
              {selectedCategories.map((category) => (
                <Chip
                  key={category.id}
                  className={clsx(category.predicted && 'event-categories__box__item--predicted')}
                  label={
                    <div className="event-categories__box__item__label">
                      {category.predicted && (
                        <RobotIcon
                          fill={robotIconColor}
                          className="event-categories__box__item__label__robot"
                        />
                      )}
                      {category.name}
                    </div>
                  }
                />
              ))}
            </Box>
          )}
          MenuProps={{
            PaperProps: {
              className: 'event-categories__menu',
            },
          }}
        >
          {defaultEventCategorySet.map((category) => (
            <MenuItem key={category.id} value={category.id}>
              {category.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

import { FC, ReactNode } from 'react';
import {
  CalendarProvider,
  CircadianProvider,
  MetricsProvider,
  TodoTasksProvider,
} from '../data-access';
import { Outlet } from 'react-router-dom';

export const BasisProvider: FC = () => {
  return (
    <CalendarProvider>
      <TodoTasksProvider>
        <CircadianProvider>
          <MetricsProvider>
            <Outlet />
          </MetricsProvider>
        </CircadianProvider>
      </TodoTasksProvider>
    </CalendarProvider>
  );
};

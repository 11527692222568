import { FC } from 'react';

import './PhaseItem.scss';

export interface PhaseItemProps {
  color: string;
  startTime: string;
  endTime: string;
  title: string;
}

const PhaseItem: FC<PhaseItemProps> = ({ title, color, startTime, endTime }) => {
  return (
    <div className="phase-item" style={{ backgroundColor: `${color}1A` }}>
      <div className="phase-item__title">{title}</div>
      <div className="phase-item__time">
        {startTime} - {endTime}
      </div>
    </div>
  );
};

export default PhaseItem;

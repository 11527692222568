import React, { useState, useEffect, useMemo } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { DateTimePicker } from '@mui/x-date-pickers';
import { DateTimeSet } from '@demind-inc/core';
import { Button } from '@mui/material';

import './TaskDueDatePicker.scss';
import { useGeneralSettings } from '../../../hooks/useGeneralSettings';

export type DateTimeSetOnlyTime = Omit<DateTimeSet, 'timezone'>;

interface TaskDueDatePickerProps {
  label: string;
  dateSet?: DateTimeSetOnlyTime;
  maxDateTime?: Dayjs;
  minDateTime?: Dayjs;
  onDateChange: (date: DateTimeSetOnlyTime) => void;
}

export const TaskDueDatePicker: React.FC<TaskDueDatePickerProps> = ({
  label,
  dateSet,
  maxDateTime,
  minDateTime,
  onDateChange,
}) => {
  const [open, setOpen] = useState(false);
  const [dateTimePickerVisible, setDateTimePickerVisible] = useState(false);
  const [dateTimeDetailPickerVisible, setDateTimeDetailPickerVisible] = useState(false);
  const {
    generalSettings: { timeFormat },
  } = useGeneralSettings();

  useEffect(() => {
    const hasDateSet = dateSet ? !!Object.values(dateSet).filter((i) => !!i).length : false;
    setDateTimePickerVisible(hasDateSet);
    setDateTimeDetailPickerVisible(!!dateSet?.datetime);
  }, [dateSet]);

  const datePickerTimeFormat = useMemo(() => {
    return timeFormat === '12h' ? 'MMMM DD h:mm A' : 'MMMM DD H:mm';
  }, [timeFormat]);

  return (
    <div className="task-due-date-picker">
      {dateTimePickerVisible && dateTimeDetailPickerVisible && (
        <DateTimePicker
          open={open}
          onClose={() => setOpen(false)}
          maxDateTime={maxDateTime}
          minDateTime={minDateTime}
          format={datePickerTimeFormat}
          value={
            dateSet?.datetime ? dayjs(dateSet.datetime) : dateSet?.date ? dayjs(dateSet.date) : null
          }
          views={['month', 'day', 'hours', 'minutes']}
          onChange={(newValue) => {
            if (newValue) {
              onDateChange({
                datetime: newValue.toISOString(),
                date: newValue.format('YYYY-MM-DD'),
              });
            }
          }}
          className="task-due-date-picker__date-picker"
          slotProps={{
            openPickerIcon: {
              style: { display: 'none' },
            },
            textField: {
              variant: 'standard',
              onClick: () => setOpen(true),
            },
          }}
          ampm={timeFormat === '12h'}
        />
      )}
      {dateTimePickerVisible && !dateTimeDetailPickerVisible && (
        <div className="task-due-date-picker__detail-group">
          <DateTimePicker
            value={dateSet?.date ? dayjs(dateSet.date) : null}
            views={['month', 'day']}
            maxDateTime={maxDateTime}
            minDateTime={minDateTime}
            onChange={(newValue) => {
              if (newValue) {
                onDateChange({
                  date: newValue.format('YYYY-MM-DD'),
                });
              }
            }}
            className="task-due-date-picker__date-picker"
            slotProps={{
              openPickerIcon: {
                style: { display: 'none' },
              },
              textField: {
                variant: 'standard',
                onClick: () => setOpen(true),
              },
            }}
            ampm={timeFormat === '12h'}
          />
          <Button
            variant="text"
            className="task-due-date-picker__add-time"
            onClick={() => setDateTimeDetailPickerVisible(true)}
          >
            + Add time
          </Button>
        </div>
      )}
      {!dateTimePickerVisible && (
        <Button
          variant="text"
          className="task-due-date-picker__add-time"
          onClick={() => {
            setDateTimePickerVisible(true);
            setDateTimeDetailPickerVisible(true);
          }}
        >
          + Add {label}
        </Button>
      )}
    </div>
  );
};

import { FC, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import { TaskAlt } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';

import './AddProjectDialog.scss';

interface AddProjectDialogProps {
  visible: boolean;
  isAdding?: boolean;
  onAdd: (name: string) => Promise<void>;
  onClose: () => void;
}

const AddProjectDialog: FC<AddProjectDialogProps> = ({
  isAdding = false,
  visible,
  onAdd,
  onClose,
}) => {
  const [projectName, setProjectName] = useState('');

  const handleClose = () => {
    onClose();
    setProjectName('');
  };

  const handleAdd = async () => {
    if (!projectName) {
      return;
    }
    await onAdd(projectName);
    handleClose();
  };

  return (
    <Dialog open={visible} onClose={handleClose} className="add-project-dialog">
      <DialogTitle>Add project</DialogTitle>
      <DialogContent className="add-project-dialog__content">
        <TextField
          autoFocus
          required
          value={projectName}
          onChange={(e) => setProjectName(e.target.value)}
          margin="dense"
          id="name"
          name="name"
          label="Name"
          type="text"
          fullWidth
          variant="standard"
        />
        <div className="add-project-dialog__content__app">
          <TaskAlt className="add-project-dialog__content__app__icon" />
          <p>Lifestack</p>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <LoadingButton loading={isAdding} onClick={handleAdd} disabled={!projectName}>
          Create
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default AddProjectDialog;

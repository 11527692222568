import { useQuery } from '@tanstack/react-query';
import { ReferralCampaign } from '@demind-inc/core';

import { accountApi } from '../api';

interface UseReferralCampaignParams {
  campaignId: string;
}

interface UseReferralCampaignQueryParams {
  queryKey: ['lifestack.subscription.campaign', UseReferralCampaignParams];
}

export const useReferralCampaign = ({ campaignId }: UseReferralCampaignParams) => {
  const referralCampaignQuery = useQuery({
    queryKey: ['lifestack.subscription.campaign', { campaignId }],
    queryFn: ({ queryKey }: UseReferralCampaignQueryParams) => {
      const [, { campaignId }] = queryKey;
      return accountApi
        .getReferralCampaign(campaignId)
        .then(({ data }) => data as ReferralCampaign);
    },
    refetchOnWindowFocus: false,
    enabled: !!campaignId,
    staleTime: 15000, // 15s
  });

  return {
    referralCampaign: referralCampaignQuery.data,
    ...referralCampaignQuery,
  };
};

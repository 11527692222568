import React from 'react';
import clsx from 'clsx';
import { Settings } from '@mui/icons-material';
import { Avatar } from '@mui/material';

import './SettingsBlock.scss';
import { useAuthContext } from '../../../data-access';

interface SettingsBlockProps {
  className?: string;
}

const SettingsBlock: React.FC<SettingsBlockProps> = ({ className }) => {
  const { user } = useAuthContext();
  return (
    <div className={clsx('settings-block', className)}>
      <div className="settings-block__name">{user.email}</div>
      <Avatar alt="user-image" src={user.photoUrl} />
    </div>
  );
};

export default SettingsBlock;

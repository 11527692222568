import dayjs, { Dayjs } from 'dayjs';
import { TimeFormatType } from '@demind-inc/core';

export const formatTaskTimeRange = ({
  startDatetime,
  endDatetime,
  timeFormat,
}: {
  startDatetime?: Dayjs;
  endDatetime?: Dayjs;
  timeFormat: TimeFormatType;
}) => {
  const format = timeFormat === '12h' ? 'h:mm A' : 'HH:mm';

  const formatTime = (dateTime: Dayjs) => {
    return dateTime ? dateTime.format(format) : '';
  };

  const startTime = formatTime(startDatetime);
  const endTime = formatTime(endDatetime);

  if (!startTime || !endTime) {
    return null;
  }

  return `${startTime} - ${endTime}`;
};

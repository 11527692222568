import { useMutation, useQueryClient } from '@tanstack/react-query';

import { TaskError401Res, tasksApi } from '../api';
import { AddTodoTaskItemProps } from '../types';
import { useRecoilState } from 'recoil';
import { taskErrorSnackBarAtom } from '../recoil';
import { useEffect } from 'react';
import { AxiosError } from 'axios';
import { TodoIntegrationType } from '@demind-inc/core';

interface UseAddTodoTaskParams {
  boardId: string;
  userId: string;
  newTaskInfo: AddTodoTaskItemProps;
}

export const useAddTodoTask = () => {
  const queryClient = useQueryClient();
  const [_, setTaskErrorSnackbar] = useRecoilState(taskErrorSnackBarAtom);

  const addTaskMutation = useMutation({
    mutationFn: ({ boardId, userId, newTaskInfo }: UseAddTodoTaskParams) => {
      return tasksApi.addTodoTask(boardId, userId, newTaskInfo).then(({ data }) => data.taskId);
    },

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['lifestack.todo.tasks'] });
    },
  });

  useEffect(() => {
    const error = (addTaskMutation.error as AxiosError)?.response;
    if (!error) {
      return;
    }
    if (error.status === 401) {
      setTaskErrorSnackbar({
        status: error.status,
        message: 'Invalid token.',
        provider: (error.data as TaskError401Res).provider as TodoIntegrationType,
        action: 'add',
      });
      return;
    }
    setTaskErrorSnackbar({
      status: error.status,
      message: 'Failed to add task.',
      provider: (error.data as TaskError401Res).provider as TodoIntegrationType,
      action: 'add',
    });
  }, [addTaskMutation.error]);

  return {
    addTodoTask: addTaskMutation.mutateAsync,
    ...addTaskMutation,
  };
};

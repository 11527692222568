import { useQuery, useQueryClient } from '@tanstack/react-query';
import { User } from '@demind-inc/core';

import { accountApi } from '../api';
import { useCallback } from 'react';

interface UseUserParams {
  userId: string;
}

interface UseUserQueryParams {
  queryKey: ['lifestack.user', { userId: string }];
}

export const useUser = ({ userId }: UseUserParams) => {
  const queryClient = useQueryClient();

  const userQuery = useQuery({
    queryKey: ['lifestack.user', { userId }],
    queryFn: ({ queryKey }: UseUserQueryParams) => {
      const [, { userId }] = queryKey;

      return accountApi.getUser(userId).then(({ data }) => data as User);
    },
    refetchOnWindowFocus: false,
    enabled: !!userId,
    gcTime: 1000 * 60 * 60 * 24 * 30, // cache time - 30 days
    staleTime: 5000,
  });

  const refetchUser = useCallback(async () => {
    await queryClient.invalidateQueries({ queryKey: ['lifestack.user', userId] });
  }, [queryClient, userId]);

  return {
    user: (userQuery.data ?? {}) as User,
    refetchUser,
    ...userQuery,
  };
};

import { FC, useState } from 'react';
import { Button, CircularProgress, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';

import './CalendlyConfigurePage.scss';
import { useAuthContext, useCalendlyIntegInfo, useConfigureCalendly } from '../../../data-access';
import { CalendlyLifestackLogo } from '../CalendlyLifestackLogo';

const CalendlyConfigurePage: FC = () => {
  const [originalScheduleLink, setOriginalScheduleLink] = useState<string>('');

  const { user } = useAuthContext();
  const { configureCalendly, isPending: isConfiguring } = useConfigureCalendly();
  const { calendlyIntegInfo, isLoading: isLoadingCalendlyInfo } = useCalendlyIntegInfo({
    calendlyIntegId: user.calendlyIntegrationId,
  });
  const navigate = useNavigate();

  const handleScheduleLinkSubmit = async () => {
    const calendlyConfigId = await configureCalendly({
      userId: user.userId,
      newConfig: {
        originalScheduleLink,
      },
    });

    navigate(`/calendly/schedule/${calendlyConfigId}`);
  };

  return (
    <div className="calendly-configure">
      <CalendlyLifestackLogo />
      <div className="calendly-configure__input">
        {isLoadingCalendlyInfo && <CircularProgress />}
        {!isLoadingCalendlyInfo && (
          <>
            {calendlyIntegInfo ? (
              <Button
                variant="contained"
                onClick={() =>
                  navigate(`/calendly/schedule/${calendlyIntegInfo.calendlyIntegrationId}`)
                }
              >
                Visit energy schedule link
              </Button>
            ) : (
              <>
                <TextField
                  placeholder="Your calendly schedule link (e.g., https://calendly.com/demind/meeting-lifestack)"
                  value={originalScheduleLink}
                  className="calendly-configure__input__field"
                  onChange={(e) => setOriginalScheduleLink(e.target.value)}
                />
                <LoadingButton
                  variant="contained"
                  disabled={!originalScheduleLink}
                  loading={isConfiguring}
                  onClick={handleScheduleLinkSubmit}
                >
                  Get energy schedule link
                </LoadingButton>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default CalendlyConfigurePage;

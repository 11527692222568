import { FC } from 'react';
import { Close } from '@mui/icons-material';
import { clsx } from 'clsx';

import './CalendlyLifestackLogo.scss';
import CalendlyLogo from '../../../assets/calendly/calendly_logo.png';
import LifestackLogo from '../../../assets/logo/logo_with_name.png';

interface CalendlyLifestackLogoProps {
  logoWidth?: string;
  className?: string;
}

const CalendlyLifestackLogo: FC<CalendlyLifestackLogoProps> = ({
  logoWidth = '250px',
  className,
}) => {
  return (
    <div className={clsx('calendly-lifestack-logo', className)}>
      <img
        src={CalendlyLogo}
        alt="calendly logo"
        className="calendly-configure__header__logo"
        style={{ width: logoWidth }}
      />
      <Close fontSize="small" />
      <img
        src={LifestackLogo}
        alt="lifestack logo"
        className="calendly-configure__header__logo"
        style={{ width: logoWidth }}
      />
    </div>
  );
};

export default CalendlyLifestackLogo;

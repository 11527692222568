import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';

import {
  ConnectDevicesPage,
  ConnectTodoPage,
  EnergyPreferencePage,
  InitialSyncPage,
} from '../pages';

export const OnboardingRoutes: FC = () => {
  return (
    <Routes>
      <Route path={'connect-devices'} element={<ConnectDevicesPage />} />

      <Route path={'initial-sync'} element={<InitialSyncPage />} />
      <Route path={'energy-preference'} element={<EnergyPreferencePage />} />
      <Route path={'connect-todo'} element={<ConnectTodoPage />} />
    </Routes>
  );
};

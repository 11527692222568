import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from '@mui/material';
import { CannyPostOptionnName, postIdsNameMap } from '../../../../data-access';

interface FeedbackDoneDialogProps {
  open: boolean;
  feedbackPostName: CannyPostOptionnName;
  onClose: () => void;
}

const FeedbackDoneDialog: React.FC<FeedbackDoneDialogProps> = ({
  open,
  feedbackPostName,
  onClose,
}) => {
  const url = postIdsNameMap[feedbackPostName]?.url ?? 'https://lifestack.canny.io';

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Feedback Submitted</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Thank you for your feedback! We appreciate your input and will use it to improve
          Lifestack. Check your feedback{' '}
          <a href={url} target="_blank">
            here
          </a>
          .
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FeedbackDoneDialog;

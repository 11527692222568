import { useQueries } from '@tanstack/react-query';
import { TaskItem } from '@demind-inc/core';

import { tasksApi } from '../api';
import { TasksByDate } from '../types';

interface UseTodoTasksParams {
  todoTasksBoardIds: string[];
  dueDate: string;
  onlyMyTask?: boolean;
}

interface UseTodoTasksByDatesParams {
  todoTasksBoardIds: string[];
  dates: string[];
  onlyMyTask?: boolean;
}

interface UseTodoTasksQueryParams {
  queryKey: ['lifestack.todo.tasks', UseTodoTasksParams];
}

export const useTodoTasks = ({
  todoTasksBoardIds,
  dates,
  onlyMyTask,
}: UseTodoTasksByDatesParams) => {
  const todoTasksQueries = useQueries({
    /* @ts-ignore */
    queries: dates.map((dueDate) => ({
      queryKey: ['lifestack.todo.tasks', { todoTasksBoardIds, dueDate, onlyMyTask }],
      queryFn: ({ queryKey }: UseTodoTasksQueryParams) => {
        const [, { todoTasksBoardIds, dueDate, onlyMyTask }] = queryKey;

        return tasksApi
          .getTodoTasks(todoTasksBoardIds, dueDate, onlyMyTask)
          .then(({ data }) => ({ date: dueDate, tasks: data as TaskItem[] }) as TasksByDate);
      },
      refetchOnWindowFocus: false,
      enabled: !!todoTasksBoardIds.length,
      staleTime: 15000, // 15s
    })),
  });

  return {
    todoTasksByDate: todoTasksQueries.map((query) => query.data ?? []).flat(),
    isLoading: todoTasksQueries.some((query) => query.isLoading),
    ...todoTasksQueries,
  };
};

import { FC, useMemo, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import { ArrowDropUp } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { useRecoilState } from 'recoil';

import './FeatureFeedbackDialog.scss';
import {
  CannyPostOptionnName,
  useUpdateCannyPost,
  visibleFeedbackDialogPostNameAtom,
} from '../../../data-access';
import clsx from 'clsx';
import { FeedbackDoneDialog } from './FeedbackDoneDialog';

const FeatureFeedbackDialog: FC = () => {
  const [visiblePostName, setVisiblePostName] = useRecoilState(visibleFeedbackDialogPostNameAtom);
  const [nickname, setNickname] = useState('');
  const [feedback, setFeedback] = useState('');
  const [upvoted, setUpvoted] = useState(false);
  const [doneDialogPostName, setDoneDialogPostName] = useState<CannyPostOptionnName | null>(null);

  const { isUpdatingCannyPost, updateCannyPost } = useUpdateCannyPost();

  const handleClose = () => {
    setVisiblePostName(null);
    setNickname('');
    setFeedback('');
    setUpvoted(false);
  };

  const canSend = useMemo(
    () => !!nickname && !!visiblePostName && (upvoted || !!feedback),
    [nickname, visiblePostName, feedback, upvoted]
  );

  const handleAnswer = async () => {
    if (!canSend) {
      return;
    }
    const postName = visiblePostName;
    try {
      await updateCannyPost({
        nickname,
        postName,
        updates: {
          vote: upvoted,
          comment: feedback,
        },
      });
      setDoneDialogPostName(postName);
    } finally {
      handleClose();
    }
  };

  return (
    <>
      <Dialog open={!!visiblePostName} onClose={handleClose} className="feature-feedback-dialog">
        <DialogTitle>Tell us about your thoughts on this upcoming feature!</DialogTitle>
        <DialogContent className="feature-feedback-dialog__content">
          <div className="feature-feedback-dialog__content__description">
            Your feedback will help us enhance our product. It will be reflected anonymously, along
            with your nickname, in{' '}
            <a href={'https://lifestack.canny.io'} target="_blank">
              our public roadmap
            </a>
            .
          </div>
          <TextField
            autoFocus
            required
            value={nickname}
            onChange={(e) => setNickname(e.target.value)}
            margin="dense"
            id="name"
            name="name"
            label="Nickname"
            type="text"
            fullWidth
            variant="standard"
          />
          <div className="feature-feedback-dialog__content__answer-group">
            <div className="feature-feedback-dialog__upvote">
              <div
                className={clsx(
                  'feature-feedback-dialog__upvote__btn',
                  upvoted && 'feature-feedback-dialog__upvote__btn--upvoted'
                )}
                onClick={() => setUpvoted(!upvoted)}
              >
                <ArrowDropUp
                  className={clsx(
                    'feature-feedback-dialog__upvote__btn__icon',
                    upvoted && 'feature-feedback-dialog__upvote__btn__icon--upvoted'
                  )}
                />
              </div>
              <div className="feature-feedback-dialog__upvote__label">
                {upvoted ? 'Upvoted' : 'Upvote'}
              </div>
            </div>
            <TextField
              value={feedback}
              onChange={(e) => setFeedback(e.target.value)}
              margin="dense"
              id="feedback"
              name="feedback"
              label="Comment"
              type="text"
              fullWidth
              multiline
              variant="standard"
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <LoadingButton loading={isUpdatingCannyPost} onClick={handleAnswer} disabled={!canSend}>
            Send
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <FeedbackDoneDialog
        open={!!doneDialogPostName}
        onClose={() => setDoneDialogPostName(null)}
        feedbackPostName={doneDialogPostName}
      />
    </>
  );
};

export default FeatureFeedbackDialog;

import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import { CloseOutlined } from '@mui/icons-material';
import dayjs, { Dayjs } from 'dayjs';
import { useRecoilValue } from 'recoil';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import './EditEventMenu.scss';
import {
  circadianPhaseInfo,
  selectedDateAtom,
  useAuthContext,
  useCalendarContext,
  useCircadianContext,
  useEventDetailsMenuContext,
  useTodoTasksContext,
  useUpdateTodoTask,
} from '../../../../data-access';
import { EventTimeSelector } from '../EventTimeSelector';
import { RBCEvent } from '../../CalendarTimeline';
import { EventDescription } from '../EventDescription';
import { hasSameDate } from '../helpers';
import { EventCategories } from '../EventCategories';
import { CalendarEvent, EventCategorySet } from '@demind-inc/core';
import { isEqual, toUpper } from 'lodash';
import clsx from 'clsx';
import { trackEventMixpanel } from '../../../../utils';
import { formatTaskTimeRange, getParamToUpdateTaskTimeFromCalendar } from '../../../../helpers';
import { useGeneralSettings } from '../../../../hooks/useGeneralSettings';

interface EditEventMenuProps {
  visible: boolean;
  onClose: () => void;
}

let defaultEvent: RBCEvent | undefined; // Use this for checking if the event has changed

const EditEventMenu: React.FC<EditEventMenuProps> = ({ visible, onClose }) => {
  const selectedDate = useRecoilValue(selectedDateAtom);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [startDate, setStartDate] = useState<Dayjs>(selectedDate);
  const [endDate, setEndDate] = useState<Dayjs>(selectedDate.add(30, 'minutes'));
  const [selectedCategories, setSelectedCategories] = useState<EventCategorySet[]>([]);
  const {
    selectedEvent,
    handleSaveEvent: handleSaveEventApi,
    handleDeleteEvent,
  } = useEventDetailsMenuContext();
  const { mainCalendar, findCalendarItem } = useCalendarContext();
  const {
    generalSettings: { timeFormat },
  } = useGeneralSettings();
  const { updateTodoTask } = useUpdateTodoTask();
  const { findPhaseForTaskTime } = useCircadianContext();
  const { user } = useAuthContext();

  const { targetCalendar } = useMemo(() => {
    if (!selectedEvent) return {};
    const targetCalendar = selectedEvent.calendarId
      ? findCalendarItem(selectedEvent.calendarId)
      : mainCalendar;

    return { targetCalendar };
  }, [selectedEvent]);

  useEffect(() => {
    if (!selectedEvent) {
      return;
    }

    if (!defaultEvent) {
      defaultEvent = selectedEvent;
    }

    setTitle(selectedEvent.title || '');
    setStartDate(dayjs(selectedEvent.start));
    setEndDate(dayjs(selectedEvent.end));
    setDescription(selectedEvent.description || '');
    setSelectedCategories(selectedEvent.categories || []);
  }, [selectedEvent]);

  const isStartTimeBeforeEndTime = useMemo(() => startDate.isBefore(endDate), [startDate, endDate]);
  const canSave = useMemo(
    () =>
      (!!title &&
        isStartTimeBeforeEndTime &&
        (defaultEvent?.title !== title ||
          defaultEvent?.description !== description ||
          !hasSameDate(defaultEvent?.start, startDate) ||
          !hasSameDate(defaultEvent?.end, endDate))) ||
      !isEqual(selectedCategories, defaultEvent?.categories ?? []),
    [
      isStartTimeBeforeEndTime,
      title,
      description,
      startDate,
      endDate,
      selectedCategories,
      defaultEvent,
    ]
  );
  const resetEventForm = () => {
    setTitle('');
    setStartDate(selectedDate);
    setEndDate(selectedDate.add(30, 'minutes'));
    defaultEvent = undefined;
  };

  const handleSaveEvent = () => {
    const newEventDate: Pick<CalendarEvent, 'start' | 'end'> = {
      start: {
        date: startDate.toISOString(),
        timeZone: dayjs.tz.guess(),
      },
      end: {
        date: endDate.toISOString(),
        timeZone: dayjs.tz.guess(),
      },
    };
    handleSaveEventApi({
      ...newEventDate,
      summary: title,
      description,
      categories: selectedCategories,
    });

    if (selectedEvent?.taskFrom) {
      updateTodoTask({
        userId: user.userId,
        boardId: selectedEvent.taskFrom.boardId,
        taskId: selectedEvent.taskFrom.taskId,
        newTaskInfo: {
          ...getParamToUpdateTaskTimeFromCalendar(newEventDate),
          appFrom: selectedEvent.taskFrom.from,
        },
      });
    }
    trackEventMixpanel('update_calendar_event', { title });
    resetEventForm();
  };

  const handleCancelEvent = () => {
    onClose();
    resetEventForm();
  };

  const handleKeyDown = (key: string) => {
    if (!canSave) {
      return;
    }

    if (key === 'Enter') {
      handleSaveEvent();
    }
  };
  const targetPhase = findPhaseForTaskTime(
    startDate.format('YYYY-MM-DD HH:mm'),
    endDate.format('YYYY-MM-DD HH:mm')
  );
  const phase = circadianPhaseInfo[targetPhase];

  const taskTime = formatTaskTimeRange({
    startDatetime: startDate,
    endDatetime: endDate,
    timeFormat,
  });
  if (!selectedEvent) {
    return null;
  }

  return (
    <Dialog
      open={visible}
      className="edit-event-menu"
      hideBackdrop
      onClose={handleCancelEvent}
      onKeyDown={({ key }) => handleKeyDown(key)}
    >
      <DialogTitle className="edit-event-menu__header">
        <p>Event</p>
        <CloseOutlined onClick={handleCancelEvent} className="edit-event-menu__header__icon" />
      </DialogTitle>
      <DialogContent className="edit-event-menu__content">
        <div className="edit-event-menu__title-container">
          <TextField
            value={title}
            placeholder="Add title"
            onChange={(e) => setTitle(e.target.value)}
            variant="standard"
            className="edit-event-menu__title"
          />

          <EventDescription
            description={description}
            onChangeDescription={setDescription}
            className="edit-event-menu__content__item"
          />
        </div>
        {taskTime && phase && (
          <div className="edit-event-menu__phase-container">
            {taskTime && (
              <div
                className="edit-event-menu__phase-container__time"
                style={{ borderColor: phase?.color, color: phase?.color }}
              >
                {taskTime}
              </div>
            )}
            {phase?.label && (
              <div
                className="edit-event-menu__phase-container__phase"
                style={{ backgroundColor: phase.color }}
              >
                {phase.label}
              </div>
            )}
          </div>
        )}
        <EventTimeSelector
          startDateTime={startDate}
          endDateTime={endDate}
          onStartTimeChange={(val) => setStartDate(val)}
          onEndTimeChange={(val) => setEndDate(val)}
        />
        <EventCategories
          selectedCategories={selectedCategories}
          setSelectedCategories={setSelectedCategories}
          className="edit-event-menu__content__item"
        />{' '}
        {!!targetCalendar && (
          <div className={clsx('edit-event-menu__content__item')}>
            <AccountCircleIcon className="edit-event-menu__content__item__icon" fontSize="small" />
            <div
              className={clsx('edit-event-menu__content__item__text', 'edit-event-menu__name-text')}
            >
              {toUpper(targetCalendar.name)}
            </div>
          </div>
        )}
      </DialogContent>
      <DialogActions className="edit-event-menu__actions">
        <div className="edit-event-menu__button-group">
          <Button
            onClick={() => handleDeleteEvent(selectedEvent)}
            variant="text"
            className="edit-event-menu__button-group__button delete-button"
          >
            Delete
          </Button>

          <Button
            disabled={!canSave}
            onClick={handleSaveEvent}
            variant="contained"
            className="edit-event-menu__button-group__button"
          >
            Save
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default EditEventMenu;

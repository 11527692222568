import { useQuery } from '@tanstack/react-query';

import { integrationsApi } from '../api';
import { CalendlyIntegration } from '@demind-inc/core';

interface UseCalendlyIntegInfoParams {
  calendlyIntegId?: string;
}

interface UseCalendlyIntegInfoQueryParams {
  queryKey: ['lifestack.integration.calendly', { calendlyIntegId: string }];
}

export const useCalendlyIntegInfo = ({ calendlyIntegId = '' }: UseCalendlyIntegInfoParams) => {
  const calendlyconfigInfoQuery = useQuery({
    queryKey: ['lifestack.integration.calendly', { calendlyIntegId }],
    queryFn: ({ queryKey }: UseCalendlyIntegInfoQueryParams) => {
      const [, { calendlyIntegId }] = queryKey;

      return integrationsApi
        .getCalendlyIntegInfo(calendlyIntegId)
        .then(({ data }) => data as CalendlyIntegration);
    },
    refetchOnWindowFocus: false,
    enabled: !!calendlyIntegId,
    staleTime: 15000, // 15s
  });

  return {
    ...calendlyconfigInfoQuery,
    calendlyIntegInfo: calendlyconfigInfoQuery.data,
  };
};

import { FC } from 'react';
import { clsx } from 'clsx';
import { Menu, MenuItem } from '@mui/material';
import { FilterAltOutlined, CheckOutlined } from '@mui/icons-material';

import './ProjectFilter.scss';
import { ProjectFilterItem, useDropdownState } from '../../../hooks';
import { AddProjectBtn } from '../AddProjectBtn';

interface ProjectFilterProps {
  filterOptions: ProjectFilterItem[];
  selectedFilter: ProjectFilterItem;
  onSelectFilter: (projectId: string) => void;
  onProjectAdd: () => void;
  className?: string;
}

const ProjectFilter: FC<ProjectFilterProps> = ({
  filterOptions,
  selectedFilter,
  onSelectFilter,
  onProjectAdd,
  className,
}) => {
  const { anchorEl, handleOpenDropdown, handleCloseDropdown } = useDropdownState();

  return (
    <>
      <div
        className={clsx('project-filter', className, anchorEl && 'project-filter__open')}
        onClick={handleOpenDropdown}
      >
        <div className="project-filter__selected-title"># {selectedFilter.name}</div>
      </div>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseDropdown}
        className="project-filter__dropdown"
      >
        {filterOptions.map((project) => (
          <MenuItem
            key={project.todoTaskId}
            onClick={() => onSelectFilter(project.todoTaskId)}
            className={clsx('project-filter__dropdown__item', {
              'project-filter__dropdown__item--selected':
                project.todoTaskId === selectedFilter.todoTaskId,
            })}
          >
            # {project.name}
          </MenuItem>
        ))}
        <AddProjectBtn onClick={onProjectAdd} className="project-filter__dropdown__add-project" />
      </Menu>
    </>
  );
};

export default ProjectFilter;

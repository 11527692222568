import { FC } from 'react';
import clsx from 'clsx';

import './SubscriptionTimeline.scss';

interface ExpiredSubscriptionTimelineProps {
  unsubscribedDate: string;
  expiredDate: string;
  className?: string;
}

const ExpiredSubscriptionTimeline: FC<ExpiredSubscriptionTimelineProps> = ({
  unsubscribedDate,
  expiredDate,
  className,
}) => {
  return (
    <div className={clsx('subscription-timeline', className)}>
      <div className="subscription-timeline__left-group">
        <div className={clsx('subscription-timeline__vertical-line', 'vertical-line')}>
          <div className="vertical-line__circle" />
          <div className="vertical-line__line" /> <div className="vertical-line__circle" />
        </div>
        <div className="subscription-timeline__title-group">
          <div className="subscription-timeline__title-group__action">{unsubscribedDate}</div>
          <div className="subscription-timeline__title-group__action">{expiredDate}</div>
        </div>
      </div>
      <div className="subscription-timeline__right-group">
        <div className="subscription-timeline__price">Unsubscribed</div>
        <div className="subscription-timeline__price">Expired</div>
      </div>
    </div>
  );
};

export default ExpiredSubscriptionTimeline;

import { useQuery } from '@tanstack/react-query';
import { Chronotype, HeatmapDataType } from '@demind-inc/core';

import { energyApi } from '../api';

interface UseAvgCircadianHeatmapParams {
  userId: string;
  metricId: string;
  referenceDate: string;
  chronotype?: Chronotype;
}

interface UseAvgCircadianHeatmapQueryParams {
  queryKey: ['lifestack.avgCircadian', UseAvgCircadianHeatmapParams];
}

export const useAvgCircadianHeatmap = ({
  userId,
  metricId,
  referenceDate,
  chronotype,
}: UseAvgCircadianHeatmapParams) => {
  const circadianQuery = useQuery({
    queryKey: ['lifestack.avgCircadian', { userId, metricId, chronotype, referenceDate }],
    queryFn: ({ queryKey }: UseAvgCircadianHeatmapQueryParams) => {
      const [, { userId, metricId, referenceDate, chronotype }] = queryKey;
      return energyApi
        .getAvgCircadian(userId, metricId, referenceDate, chronotype)
        .then(({ data }) => data as HeatmapDataType[]);
    },
    refetchOnWindowFocus: false,
    staleTime: 5000,
    enabled: !!userId && !!metricId && !!referenceDate,
  });

  return {
    avgCircadian: circadianQuery.data ?? [],
    ...circadianQuery,
  };
};

import { SVG_ICONS } from '../../constants';
import { SettingsActionItem } from './types';
type MenuItem = {
  text: string;
  icon: React.FC;
};

type SettingsMenuStructure = {
  [section: string]: {
    [key in SettingsActionItem]?: MenuItem;
  };
};

export const settingsMenuStructure: SettingsMenuStructure = {
  Account: {
    profile: { text: 'Profile', icon: SVG_ICONS.profile },
    calendars: { text: 'Calendars', icon: SVG_ICONS.calendar },
    wearables: { text: 'Wearables', icon: SVG_ICONS.wearable },
    todos: { text: 'Todos', icon: SVG_ICONS.todo },
    subscription: { text: 'Subscription', icon: SVG_ICONS.subscription },
  },
  General: {
    timeFormat: { text: 'Time Format', icon: SVG_ICONS.timeFormat },
    notifications: { text: 'Notifications', icon: SVG_ICONS.notifications },
  },
  'Product Hub': {
    mobileApp: { text: 'Mobile App', icon: SVG_ICONS.mobile },
    chromeExtension: { text: 'Chrome Extension', icon: SVG_ICONS.chromeExtension },
  },
  About: {},
};

import { useQueries } from '@tanstack/react-query';

import { energyApi } from '../api';
import { FirestoreEMADateSet } from '../types';

interface UsePhaseEmaParams {
  userId: string;
  dates: string[];
}

interface UsePhaseEmaQueryParams {
  queryKey: readonly ['phaseEma', { userId: string; date: string }];
}

export const usePhaseEma = ({ userId, dates }: UsePhaseEmaParams) => {
  const phaseEmaQueries = useQueries({
    queries: dates.map((date) => ({
      queryKey: ['phaseEma', { userId, date }] as const,
      queryFn: ({ queryKey }: UsePhaseEmaQueryParams) => {
        const [, { userId, date }] = queryKey;
        return energyApi
          .getPhaseEmas(userId, date)
          .then(({ data }) => ({ date, scores: data }) as FirestoreEMADateSet);
      },
      refetchOnWindowFocus: false,
      enabled: !!userId && !!date,
      staleTime: 15000, // 15s
    })),
  });

  return {
    phaseEmas: phaseEmaQueries.map((query) => query.data ?? []).flat(),
    ...phaseEmaQueries,
  };
};

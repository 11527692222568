import { Calendar, CalendarEvent } from '@demind-inc/core';
import { RBCEvent } from './types';

export const transformEventToRBCEvent = (
  event: CalendarEvent,
  targetCalendar?: Calendar
): RBCEvent => {
  const meAttendee = event.attendees?.find((attendee) =>
    [targetCalendar?.internalGCalendarId, targetCalendar?.rootEmail].includes(attendee.email)
  );
  const isDeclined = meAttendee ? meAttendee.responseStatus === 'declined' : false;

  return {
    ...event,
    title: event.summary,
    start: new Date(event.start.date),
    end: new Date(event.end.date),
    allDay: event.isAllDay,
    calendarColor: targetCalendar?.color!,
    isDeclined,
  };
};

export const transformCreatingEventToRBCEvent = (event: Partial<CalendarEvent>): RBCEvent => {
  return {
    ...event,
    ...(event.start ? { start: new Date(event.start.date) } : {}),
    ...(event.end ? { end: new Date(event.end.date) } : {}),
  } as RBCEvent;
};

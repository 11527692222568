import { useMemo } from 'react';
import { useAuthContext, useSyncGCalendarsList, useSyncOutlookCalendarsList } from '../data-access';

interface SyncCalendarsListParams {
  calendarType: 'google' | 'outlook';
  rootEmail: string;
}

export const useSyncCalendarLists = () => {
  const { syncGCalendarsList: syncGCalendarsListMutation, isPending: isSyncingGCal } =
    useSyncGCalendarsList();
  const {
    syncOutlookCalendarsList: syncOutlookCalendarsListMutation,
    isPending: isSyncingOutlook,
  } = useSyncOutlookCalendarsList();
  const { user } = useAuthContext();

  const isSyncing = useMemo(
    () => isSyncingGCal || isSyncingOutlook,
    [isSyncingGCal, isSyncingOutlook]
  );

  const syncCalendarsList = async ({ calendarType, rootEmail }: SyncCalendarsListParams) => {
    if (calendarType === 'google') {
      await syncGCalendarsListMutation({
        userId: user.userId,
        rootEmail,
      });
    } else if (calendarType === 'outlook') {
      await syncOutlookCalendarsListMutation({ userId: user.userId });
    }
  };

  return {
    syncCalendarsList,
    isSyncing,
  };
};

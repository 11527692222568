import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';

interface UseQueryStringParams {
  qskey: string;
}

export const useQueryString = ({ qskey }: UseQueryStringParams) => {
  const [queryString, setQueryString] = useState<string | null>(null);

  useEffect(() => {
    const queryStringFromCookie = Cookies.get(qskey);
    if (queryStringFromCookie) {
      setQueryString(queryStringFromCookie);
    }
  }, []);

  return { queryString };
};

import React from 'react';
import clsx from 'clsx';
import { DateCalendar } from '@mui/x-date-pickers';
import { useRecoilState } from 'recoil';

import './SideBar.scss';
import LifestackLogoName from '../../../assets/logo/logo_with_name.png';
import { CalendarsList, SettingsBlock, GeneralCTA, TodoList } from '../../../components';
import { selectedDateAtom, settingsModalAtom, useCalendarContext } from '../../../data-access';
import { useConnectCalendar } from '../../../hooks';
import { SIDEBAR_MENU } from './constants';
import { SideBarMinimized } from './SideBarMinimized';
import { useLocalStorage } from '@uidotdev/usehooks';

interface SideBarProps {
  className?: string;
}

const SideBar: React.FC<SideBarProps> = ({ className }) => {
  const {
    calendarsMeta,
    visibleCalendarIds,
    syncErrorRootEmail,
    isFetchingCalendars,
    handleSelectCalendar,
  } = useCalendarContext();
  const [_, setSettingsModalVisible] = useRecoilState(settingsModalAtom);
  const [selectedDate, setSelectedDate] = useRecoilState(selectedDateAtom);
  const [open, setOpen] = useLocalStorage('sidebarOpen', true);
  const { connectCal } = useConnectCalendar();
  if (!open) {
    return <SideBarMinimized setOpen={setOpen} />;
  }
  return (
    <div className={clsx('sidebar', className)}>
      <div className="sidebar__header">
        <img src={LifestackLogoName} alt="Lifestack Logo" className="sidebar__header__logo" />
        <button onClick={() => setOpen(false)} className="sidebar__header__menu-icon">
          <SIDEBAR_MENU.close title="Close Menu" />
        </button>
      </div>
      <div className="sidebar__content">
        <div className="sidebar__calendar">
          <DateCalendar
            value={selectedDate}
            onChange={(val) => setSelectedDate(val)}
            className="sidebar__calendar__content"
          />
        </div>
        <div className="sidebar__selector-group">
          <CalendarsList
            calendarsMeta={calendarsMeta}
            visibleCalendarIds={visibleCalendarIds}
            isFetchingCalendars={isFetchingCalendars}
            syncErrorRootEmail={syncErrorRootEmail}
            onSelectCalendar={handleSelectCalendar}
            onConnectCalendar={connectCal}
            className="sidebar__calendars"
          />
          <TodoList className="sidebar__todos" />
          <GeneralCTA className="sidebar__general-cta" />
          <button
            className="sidebar__content__button "
            onClick={() => setSettingsModalVisible({ visible: true })}
          >
            <div className="sidebar__content__button__icon">
              <SIDEBAR_MENU.setting />
            </div>
            <div className="sidebar__content__button__text">Settings</div>
          </button>
        </div>
      </div>
      <SettingsBlock className="sidebar__settings" />
    </div>
  );
};

export default SideBar;

import './EnergyBoostModalView.scss';
import Background from '../../../../../../assets/actions/energy-boost-showcase.png';
import { Button } from '@mui/material';

const EnergyBoostModalView = () => {
  return (
    <div className="energy-boost-modal">
      <img src={Background} alt="logo" className="energy-boost-modal__bg" draggable="false" />
      <div className="energy-boost-modal__description-container">
        <p className="energy-boost-modal__description">
          Automatically schedule your day with our AI, which arranges energy-boosting events on your
          calendar
        </p>
        <Button  >
          <p>Run</p>
        </Button>
      </div>
    </div>
  );
};

export default EnergyBoostModalView;

import { UserManager, UserManagerSettings } from 'oidc-client-ts';

const settings: UserManagerSettings = {
  authority: `https://login.microsoftonline.com/common/v2.0`,
  client_id: process.env.REACT_APP_OUTLOOK_CLIENT_ID,
  redirect_uri: `${window.location.origin}/callback/outlook`,
  response_type: 'code',
  scope: 'openid User.Read profile offline_access email Calendars.ReadWrite',
  automaticSilentRenew: true,
};

export const defaultOutlookCalScopes = [
  'openid',
  'User.Read',
  'profile',
  'offline_access',
  'email',
  'Calendars.ReadWrite',
];

export const msAuth = new UserManager(settings);

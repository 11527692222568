import { useMutation, useQueryClient } from '@tanstack/react-query';

import { integrationsApi } from '../api';
import { FirestoreCalendlyInteg } from '../types';

interface UseConfigureCalendlyParams {
  userId: string;
  newConfig: Partial<FirestoreCalendlyInteg>;
}

export const useConfigureCalendly = () => {
  const queryClient = useQueryClient();

  const configureCalendlyMutation = useMutation({
    mutationFn: ({ userId, newConfig }: UseConfigureCalendlyParams) => {
      return integrationsApi
        .configureCalendly(userId, newConfig)
        .then(({ data }) => data.calendlyIntegId);
    },
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['lifestack.integration.calendly'] });
    },
  });

  return {
    configureCalendly: configureCalendlyMutation.mutateAsync,
    ...configureCalendlyMutation,
  };
};

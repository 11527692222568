import { useMutation, useQueryClient } from '@tanstack/react-query';
import { calendarApi } from '../api';
import { CalendarEventModifyOption } from '../types';
import { CalendarEvent } from '@demind-inc/core';
import { merge, cloneDeep } from 'lodash';
import { useMemo } from 'react';
import { getUTCStartEndTimeOfDay } from '../helpers';
import { useRecoilValue } from 'recoil';
import { selectedDateAtom } from '../recoil';

export interface UseUpdateCalendarEventParams {
  calendarId: string;
  eventId: string;
  userId: string;
  newEventOption: CalendarEventModifyOption;
}

export function useUpdateCalendarEvent() {
  const queryClient = useQueryClient();
  const selectedDate = useRecoilValue(selectedDateAtom);
  const { startTime, endTime } = useMemo(
    () => getUTCStartEndTimeOfDay(selectedDate),
    [selectedDate]
  );

  const getQueryKey = (userId: string) => [
    'lifestack.calendar.events',
    { userId, startDate: startTime.toISOString(), endDate: endTime.toISOString() },
  ];

  const updateCalendarEventMutation = useMutation({
    mutationFn: ({ calendarId, eventId, userId, newEventOption }: UseUpdateCalendarEventParams) => {
      return calendarApi.updateCalendarEvent(userId, calendarId, eventId, newEventOption);
    },
    onMutate: async ({
      eventId: targetEventId,
      userId,
      newEventOption,
    }: UseUpdateCalendarEventParams) => {
      const prevEvents =
        queryClient.getQueriesData<CalendarEvent[]>({
          queryKey: getQueryKey(userId),
        })?.[0]?.[1] ?? [];

      await queryClient.cancelQueries({ queryKey: getQueryKey(userId) });
      queryClient.setQueriesData<CalendarEvent[]>(
        { queryKey: getQueryKey(userId) },
        (prevEvents) => {
          const newEvents = cloneDeep(prevEvents); // By cloning deeply, set a new reference to re-render the component.
          return newEvents?.map((event) =>
            event.eventId === targetEventId
              ? {
                  ...merge({}, event, newEventOption),
                  ...(newEventOption.categories ? { categories: newEventOption.categories } : {}),
                }
              : event
          );
        }
      );
      return { prevEvents };
    },
    onSuccess: (_, { userId, newEventOption }) => {
      queryClient.invalidateQueries({
        queryKey: ['lifestack.calendar.events', { userId }],
      });

      if (newEventOption.eventMetrics || newEventOption.categories?.length) {
        queryClient.invalidateQueries({ queryKey: ['lifestack.circadian'] });
      }
    },
    onError: (error, { userId }, context) => {
      // Revert the changes by restoring the previous data
      if (context.prevEvents) {
        queryClient.setQueriesData<CalendarEvent[]>(
          {
            queryKey: getQueryKey(userId),
          },
          context.prevEvents
        );
      }
    },
  });

  return {
    updateCalendarEvent: updateCalendarEventMutation.mutateAsync,
    ...updateCalendarEventMutation,
  };
}

import { FC, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers';
import { CircularProgress } from '@mui/material';
import { InlineWidget } from 'react-calendly';

import './CalendlySchedulePage.scss';
import {
  getArrayOfXDaysForwardFromToday,
  useCalendlyIntegInfo,
  usePhases,
} from '../../../data-access';
import { PhaseList } from '../../../components';
import { Dayjs } from 'dayjs';
import { CalendlyLifestackLogo } from '../CalendlyLifestackLogo';

const PHASE_FETCH_DURATION = 30;

//TODO: overlay the energy on the calendar event
const CalendlySchedulePage: FC = () => {
  const { calendlyIntegId } = useParams();
  const { calendlyIntegInfo, isLoading: isFetchingCalendlyInfo } = useCalendlyIntegInfo({
    calendlyIntegId,
  });
  const { phases, isLoading: isFetchingPhases } = usePhases({
    metricId: calendlyIntegInfo?.authUserMetricId,
    dates: getArrayOfXDaysForwardFromToday(PHASE_FETCH_DURATION).map((date) =>
      date.format('YYYY-MM-DD')
    ),
  });
  const [targetDate, setTargetDate] = useState<Dayjs>(dayjs());

  const targetPhases = useMemo(() => {
    if (!phases?.length) {
      return;
    }

    return phases.find(({ date }) => date === targetDate.format('YYYY-MM-DD')).details;
  }, [phases, targetDate]);

  if (isFetchingCalendlyInfo) {
    return (
      <div className="calendly-schedule">
        <CircularProgress />
      </div>
    );
  }

  if (!calendlyIntegInfo) {
    return <div className="calendly-schedule">No data</div>;
  }

  return (
    <div className="calendly-schedule">
      <div className="calendly-schedule__header">
        <CalendlyLifestackLogo logoWidth="150px" />
      </div>
      <div className="calendly-schedule__content">
        <InlineWidget url={calendlyIntegInfo.originalScheduleLink} />
        <div className="calendly-schedule__content__phase">
          <div className="calendly-schedule__content__phase__title">Energy schedule</div>
          <DatePicker value={targetDate} onChange={(val) => setTargetDate(val)} />
          {!!targetPhases && (
            <PhaseList
              phases={targetPhases}
              excludePhases={['default', 'evening_normal', 'wakeup_normal', 'morning_normal']}
              className="calendly-schedule__content__phase__list"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default CalendlySchedulePage;

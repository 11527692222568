import { useQueries } from '@tanstack/react-query';
import dayjs from 'dayjs';

import { energyApi } from '../api';
import { CircadianRhythmByDate } from '../types';

interface UseCircadianHeatmapParams {
  userId: string;
  calendarIds: string[];
  metricId: string;
  dates: string[];
  timezone?: string;
  enableEmaCombine?: boolean;
}

interface UseSleepDetailsQueryParams {
  queryKey: [
    'lifestack.circadian',
    { userId: string; calendarIds: string[]; metricId: string; date: string },
  ];
}

export const useCircadianHeatmap = ({
  userId,
  calendarIds,
  metricId,
  dates,
  timezone = dayjs.tz.guess(),
  enableEmaCombine = false,
}: UseCircadianHeatmapParams) => {
  const circadianQueries = useQueries({
    // @ts-ignore
    queries: dates.map((date) => ({
      queryKey: ['lifestack.circadian', { userId, calendarIds, metricId, date }],
      queryFn: ({ queryKey }: UseSleepDetailsQueryParams) => {
        const [, { userId, calendarIds, metricId, date }] = queryKey;
        return energyApi
          .getCircadian(userId, calendarIds, metricId, date, timezone, enableEmaCombine)
          .then(
            ({ data }) =>
              ({
                date,
                values: data.circadian,
                fluctuations: data.fluctuations,
              }) as CircadianRhythmByDate
          );
      },
      refetchOnWindowFocus: false,
      enabled: !!metricId && !!calendarIds.length && !!userId && !!date,
      staleTime: 15000, // 15s
    })),
  });

  return {
    circadianRhythmsByDate: circadianQueries
      .map((query) => query.data ?? [])
      .flat() as CircadianRhythmByDate[],
    isLoading: circadianQueries.some((query) => query.isLoading),
    ...circadianQueries,
  };
};

import { GeneralPreference } from '@demind-inc/core';
import { useAuthContext, useGeneralPreference, useUpdateGeneralPref } from '../data-access';

export const useGeneralSettings = () => {
  const { user } = useAuthContext();
  const { generalPreferences } = useGeneralPreference({ preferenceId: user.preferenceId! });
  const { updateGeneralPref, isPending } = useUpdateGeneralPref();

  const handleGeneralSettingsChange = (newSettings: Partial<GeneralPreference>) => {
    updateGeneralPref({ preferenceId: user.preferenceId!, preferences: newSettings });
  };

  return {
    generalSettings: generalPreferences,
    handleGeneralSettingsChange,
    isPending,
  };
};

import React, { useMemo, useCallback, useRef } from 'react';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { FixedSizeList as List } from 'react-window'; // Import react-window
import { useRecoilState } from 'recoil';

import './KanbanView.scss';
import {
  KanbanList,
  ProjectFilter,
  ManageProjectsBtn,
  AddProjectDialog,
  TaskDetailModal,
  ConnectTodosDropdown,
} from '../../../components';
import {
  useTodoTasksContext,
  useOverdueTasks,
  selectedDateAtom,
  visibleTaskDetailAtom,
  useAuthContext,
  useTodoIntegrations,
} from '../../../data-access';
import {
  useStartTodoAuth,
  useProjectFilter,
  useMoveTask,
  useAddProject,
  useDropdownState,
} from '../../../hooks';
import { debounce } from 'lodash';

interface KanbanViewProps {
  className?: string;
}
const CARD_WIDTH = 320;
const LIST_VIEW = 1200;

const KanbanView: React.FC<KanbanViewProps> = ({ className }) => {
  const [selectedDate, setSelectedDate] = useRecoilState(selectedDateAtom);
  const listRef = useRef(null);
  const {
    todoTasksByDate,
    visibleTodoListDates,
    todoProjects,
    isFetchingTasks,
    isFetchingProjects,
    visibleProjectIds,
    loadMoreTasks,
  } = useTodoTasksContext();
  const [visibleTask, setVisibleTask] = useRecoilState(visibleTaskDetailAtom);
  const { anchorEl, handleCloseDropdown } = useDropdownState();
  const { user } = useAuthContext();
  const { todoIntegrations } = useTodoIntegrations({ userId: user?.userId });

  const { handleOpenTodoAuth, generatingTodoAuth } = useStartTodoAuth({});
  const { selectedFilter, filterProjectOptions, handleSelectFilterProject } = useProjectFilter();
  const { handleMoveTask } = useMoveTask();
  const { projectDialogVisible, isAddingProject, handleProjectDialogVisible, handleAddProject } =
    useAddProject();

  const overDueDateTill = dayjs().subtract(1, 'day'); // Get overdue tasks till yesterday

  const { data: overdueTasks } = useOverdueTasks({
    visibleProjectIds,
    dueDateTill: overDueDateTill.format('YYYY-MM-DD'),
  });

  const hasNoProjects = !todoProjects.length && !isFetchingProjects;

  const kanbanItems = useMemo(() => {
    const regularItems = visibleTodoListDates.map((listDate) => ({
      date: listDate,
      tasks:
        todoTasksByDate.find(({ date }) => date === listDate.format('YYYY-MM-DD'))?.tasks || [],
      isOverDue: false,
    }));
    return regularItems;
  }, [overdueTasks, visibleTodoListDates, todoTasksByDate]);

  const renderKanbanItem = useCallback(
    ({ index, style }) => {
      const { date, tasks, isOverDue } = kanbanItems[index];
      return (
        <div style={{ ...style }}>
          <KanbanList
            date={date}
            tasks={tasks}
            isFetchingTasks={isFetchingTasks}
            isOverDue={isOverDue}
            className="kanban-view__content__list"
            onMoveTask={(task) => {
              handleMoveTask({
                task,
                targetDate: date,
                wasOverdue: task.dueDateTime?.datetime
                  ? dayjs(task.dueDateTime.datetime)
                      .endOf('day')
                      .isBefore(dayjs(selectedDate).startOf('day'))
                  : task.dueDateTime?.date
                    ? dayjs(task.dueDateTime.date)
                        .endOf('day')
                        .isBefore(dayjs(selectedDate).startOf('day'))
                    : isOverDue,
              });
            }}
          />
        </div>
      );
    },
    [visibleTodoListDates, todoTasksByDate, selectedDate]
  );

  const debounceScroll = debounce(({ scrollOffset }) => {
    const firstVisibleIndex = Math.floor(scrollOffset / CARD_WIDTH);
    if (firstVisibleIndex >= 0 && firstVisibleIndex < kanbanItems.length) {
      setSelectedDate(kanbanItems[firstVisibleIndex].date);
    }

    const maxScrollOffset = kanbanItems.length * CARD_WIDTH - LIST_VIEW;
    if (scrollOffset >= maxScrollOffset - 100) {
      loadMoreTasks();
    }
  }, 200);

  const handleResetDate = () => {
    setSelectedDate(dayjs());
    listRef?.current?.scrollToItem(0);
  };

  return (
    <div className={clsx('kanban-view', className)}>
      <div className="kanban-view__header">
        <div className="kanban-view__header__left-group">
          <button onClick={handleResetDate} className="kanban-view__header__title">
            {dayjs().format('MMM D')}
          </button>
        </div>
        {hasNoProjects ? (
          <ManageProjectsBtn onConnect={handleOpenTodoAuth} />
        ) : (
          <ProjectFilter
            filterOptions={filterProjectOptions}
            selectedFilter={selectedFilter}
            onProjectAdd={() => handleProjectDialogVisible(true)}
            onSelectFilter={handleSelectFilterProject}
          />
        )}
      </div>
      <div className={clsx('kanban-view__content')}>
        {/* Overdue list */}
        <KanbanList
          key={selectedDate.toISOString()}
          date={overDueDateTill}
          tasks={overdueTasks}
          isFetchingTasks={isFetchingTasks}
          isOverDue={true}
          className="kanban-view__content__list kanban-view__content__list--overdue"
          onMoveTask={(task) =>
            handleMoveTask({ task, targetDate: selectedDate, wasOverdue: true })
          }
        />

        <List
          height={'100%'}
          itemCount={kanbanItems.length}
          itemSize={CARD_WIDTH}
          layout="horizontal"
          width={LIST_VIEW}
          onScroll={debounceScroll}
          ref={listRef}
        >
          {renderKanbanItem}
        </List>
      </div>
      <AddProjectDialog
        visible={projectDialogVisible}
        isAdding={isAddingProject}
        onAdd={handleAddProject}
        onClose={() => handleProjectDialogVisible(false)}
      />
      <TaskDetailModal
        task={visibleTask!}
        visible={!!visibleTask}
        onClose={() => setVisibleTask(null)}
      />
      <ConnectTodosDropdown
        generatingTodoAuth={generatingTodoAuth}
        anchorEl={anchorEl}
        onClose={handleCloseDropdown}
        onClickItem={handleOpenTodoAuth}
        connectedTodos={todoIntegrations}
      />
    </div>
  );
};

export default KanbanView;

import { ReactNode, createContext, useCallback, useContext, useMemo } from 'react';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { CircadianPhase, HeatmapDataType, PhaseStartEndSet } from '@demind-inc/core';
import {
  selectedDateAtom,
  useAuthContext,
  useCircadianHeatmap,
  usePhaseEma,
} from '../../data-access';
import {
  PeakDipStartEndSet,
  FirestoreEMADateSet,
  PeakEnergyByDate,
  CircadianPhaseBoundariesByDate,
  PeaksDipsBoundariesByDate,
  CircadianRhythmByDate,
} from '../types';
import {
  calculatePeakEnergy,
  getCircdaianPhaseBoundaries,
  getPeaksDipsBoundaries,
} from '../helpers';
import { useRecoilValue } from 'recoil';

dayjs.extend(timezone);
dayjs.extend(utc);

interface ICircadianContext {
  circadianRhythmsByDate: CircadianRhythmByDate[];
  circadianRhythms: HeatmapDataType[];
  phaseEmasByDate: FirestoreEMADateSet[];
  circadianPhaseBoundariesByDate: CircadianPhaseBoundariesByDate[];
  circadianPhaseBoundaries?: PhaseStartEndSet;
  peaksDipsBoundariesByDate: PeaksDipsBoundariesByDate[];
  peaksDipsBoundaries?: PeakDipStartEndSet;
  peakEnergyByDate: PeakEnergyByDate[];
  peakEnergy?: number;
  isFetchingCircadian: boolean;
  isSyncingCircadian: boolean;
  findPhaseForTaskTime: (startTime: string, endTime: string) => CircadianPhase | undefined;
}

export const CircadianContext = createContext({} as ICircadianContext);
export const useCircadianContext = () => useContext(CircadianContext);

export const CircadianProvider = ({ children }: { children: ReactNode }) => {
  const { user } = useAuthContext();
  const selectedDate = useRecoilValue(selectedDateAtom);

  const { circadianRhythmsByDate, isLoading: isFetchingCircadian } = useCircadianHeatmap({
    userId: user.userId,
    calendarIds: user.calendarIds ?? [],
    metricId: user.metricId!,
    dates: [selectedDate.format('YYYY-MM-DD')],
    timezone: dayjs.tz.guess(),
    enableEmaCombine: user.access === 'internal',
  });

  const { phaseEmas: phaseEmasByDate } = usePhaseEma({
    userId: user.userId,
    dates: [selectedDate.format('YYYY-MM-DD')],
  });

  const selectedDateCircadianRhythms = useMemo(() => {
    if (!circadianRhythmsByDate.length) {
      return [];
    }
    const selectedDateCircadianRhythms = circadianRhythmsByDate.find(
      ({ date }) => date === selectedDate.format('YYYY-MM-DD')
    );

    return selectedDateCircadianRhythms?.values ?? [];
  }, [circadianRhythmsByDate, selectedDate]);

  const circadianPhaseBoundariesByDate = useMemo(() => {
    if (!circadianRhythmsByDate.length) {
      return [];
    }

    return circadianRhythmsByDate.map((item) => ({
      date: item.date,
      details: getCircdaianPhaseBoundaries(item.values) as PhaseStartEndSet,
    }));
  }, [circadianRhythmsByDate]);

  const { circadianPhaseBoundaries, peaksDipsBoundaries } = useMemo(() => {
    if (!selectedDateCircadianRhythms.length) {
      return {};
    }

    const circadianPhaseBoundaries = getCircdaianPhaseBoundaries(
      selectedDateCircadianRhythms
    ) as PhaseStartEndSet;

    const peaksDipsBoundaries = getPeaksDipsBoundaries(
      selectedDateCircadianRhythms
    ) as PeakDipStartEndSet;

    return {
      circadianPhaseBoundaries,
      peaksDipsBoundaries,
    };
  }, [selectedDateCircadianRhythms, selectedDate]);

  const peaksDipsBoundariesByDate = useMemo(() => {
    if (!circadianRhythmsByDate.length) {
      return [];
    }

    return circadianRhythmsByDate.map((item) => ({
      date: item.date,
      details: getPeaksDipsBoundaries(item.values) as PeakDipStartEndSet,
    }));
  }, [circadianRhythmsByDate]);

  const peakEnergy = useMemo(() => {
    if (!selectedDateCircadianRhythms.length) {
      return;
    }

    return calculatePeakEnergy(selectedDateCircadianRhythms);
  }, [selectedDateCircadianRhythms]);

  const peakEnergyByDate = useMemo(() => {
    if (!circadianRhythmsByDate.length) {
      return [];
    }

    return circadianRhythmsByDate.map((item) => ({
      date: item.date,
      peakEnergy: calculatePeakEnergy(item.values),
    }));
  }, [circadianRhythmsByDate]);

  const findPhaseForTaskTime = useCallback(
    (startTime: string, endTime: string) => {
      if (!circadianPhaseBoundaries) {
        return;
      }
      const targetDateCircadian = circadianRhythmsByDate.find(
        ({ date }) => date === dayjs(startTime).format('YYYY-MM-DD')
      );
      if (!targetDateCircadian) {
        return;
      }
      const targetDateCircadianPhaseBoundaries = getCircdaianPhaseBoundaries(
        targetDateCircadian.values
      );
      const midpointDuration = dayjs(endTime).diff(dayjs(startTime)) / 2;
      const midpoint = dayjs(startTime).add(midpointDuration, 'millisecond');

      return Object.entries(targetDateCircadianPhaseBoundaries).find(([_, { start, end }]) => {
        return midpoint.isBefore(dayjs(end)) && midpoint.isAfter(dayjs(start));
      })?.[0] as CircadianPhase;
    },
    [circadianRhythmsByDate]
  );

  return (
    <CircadianContext.Provider
      value={{
        circadianRhythmsByDate,
        phaseEmasByDate,
        circadianPhaseBoundariesByDate,
        peaksDipsBoundariesByDate,
        peakEnergyByDate,
        isFetchingCircadian,
        circadianRhythms: selectedDateCircadianRhythms,
        circadianPhaseBoundaries,
        peaksDipsBoundaries,
        peakEnergy,
        isSyncingCircadian: isFetchingCircadian,
        findPhaseForTaskTime,
      }}
    >
      {children}
    </CircadianContext.Provider>
  );
};

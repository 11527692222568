import { DailyAnalysisModalView, EnergyBoostModalView } from './CoreActionModal/ActionModalViews';

export interface Action {
  title: string;
  description: string;
  component?: React.ComponentType;
  header: string;
}

export const ACTIONS: Action[] = [
  {
    title: 'AI Scheduler',
    description: 'Schedule tasks instantly',
    header: '',
  },
  {
    title: 'Energy Boost',
    description: 'Energize your day',
    component: EnergyBoostModalView,
    header: 'Energy-Boost',
  },
  {
    title: 'Daily Analysis',
    description: "Check today's energy",
    component: DailyAnalysisModalView,
    header: 'Analysis of Your Day',
  },
];

import { FC } from 'react';
import dayjs from 'dayjs';
import { CircadianPhase, PhaseStartEndSet } from '@demind-inc/core';
import clsx from 'clsx';

import './PhaseList.scss';
import { PhaseItem } from '../PhaseItem';
import { circadianPhaseInfo } from '../../../data-access';

interface PhaseListProps {
  phases: PhaseStartEndSet;
  excludePhases?: CircadianPhase[];
  className?: string;
}

const PhaseList: FC<PhaseListProps> = ({ phases, excludePhases, className }) => {
  return (
    <div className={clsx('phase-list', className)}>
      {Object.entries(phases)
        .filter(([phase]) => !excludePhases.includes(phase as CircadianPhase))
        .sort(([, { start: aStart }], [, { start: bStart }]) =>
          dayjs(aStart).isBefore(dayjs(bStart)) ? -1 : 1
        )
        .map(([phase, info]) => {
          const circadianInfo = circadianPhaseInfo[phase];
          if (!circadianInfo) {
            return;
          }

          return (
            <PhaseItem
              key={phase}
              color={circadianInfo.color}
              startTime={dayjs(info.start).format('HH:mm')}
              endTime={dayjs(info.end).format('HH:mm')}
              title={circadianInfo.title}
            />
          );
        })}
    </div>
  );
};

export default PhaseList;

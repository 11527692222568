import dayjs from 'dayjs';

import {
  getStartEndTimeOfMultDays,
  useAuthContext,
  useSyncSleepDetails,
  wearableErrorSnackBarAtom,
} from '../data-access';
import { useEffect } from 'react';
import { AxiosError } from 'axios';
import { useRecoilState } from 'recoil';

interface SyncSleepDataParams {
  terraUserIds: string[];
  startDate: string;
  endDate: string;
}

export const useSyncSleepData = () => {
  const { syncSleepDetail, error: syncSleepDetailError } = useSyncSleepDetails();
  const { user } = useAuthContext();
  const [_, setWearableErrorSnackbar] = useRecoilState(wearableErrorSnackBarAtom);

  useEffect(() => {
    if (!syncSleepDetailError) {
      return;
    }
    const error = (syncSleepDetailError as AxiosError).response;
    if (error?.status === 401) {
      setWearableErrorSnackbar({ message: 'Device connection issue', status: 401 });
    } else {
      setWearableErrorSnackbar({ message: 'Failed to sync data', status: error?.status });
    }
  }, [syncSleepDetailError]);

  const syncSleepData = async ({ terraUserIds, startDate, endDate }: SyncSleepDataParams) => {
    if (!user.metricId || !user.userId || !terraUserIds.length) {
      return;
    }

    const { startTime, endTime } = getStartEndTimeOfMultDays(dayjs(startDate), dayjs(endDate));

    if (terraUserIds.length) {
      try {
        await syncSleepDetail({
          userId: user.userId,
          metricId: user.metricId,
          terraUserIds,
          startDate: startTime.toISOString(),
          endDate: endTime.toISOString(),
        });
      } catch (error) {
        console.error('Error syncing sleep data', error);
      }
    }
  };

  return { syncSleepData };
};

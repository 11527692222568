import { useMutation, useQueryClient } from '@tanstack/react-query';
import { TaskError401Res, tasksApi } from '../api';
import { useRecoilState } from 'recoil';
import { taskErrorSnackBarAtom } from '../recoil';
import { useEffect } from 'react';
import { AxiosError } from 'axios';
import { TodoIntegrationType } from '@demind-inc/core';

interface UseDeleteTaskProps {
  taskId: string;
  boardId: string;
  userId: string;
}

export function useDeleteTask() {
  const queryClient = useQueryClient();
  const [_, setTaskErrorSnackbar] = useRecoilState(taskErrorSnackBarAtom);

  const deleteTaskMutation = useMutation({
    mutationFn: ({ taskId, boardId, userId }: UseDeleteTaskProps) => {
      return tasksApi.deleteTodoTask(taskId, boardId, userId);
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({ queryKey: ['lifestack.todo.tasks'] });
      queryClient.invalidateQueries({ queryKey: ['lifestack.todo.overdueTasks'] });
    },
  });

  useEffect(() => {
    const error = (deleteTaskMutation.error as AxiosError)?.response;
    if (!error) {
      return;
    }
    if (error.status === 401) {
      setTaskErrorSnackbar({
        status: error.status,
        message: 'Invalid token.',
        provider: (error.data as TaskError401Res).provider as TodoIntegrationType,
        action: 'delete',
      });
      return;
    }
    setTaskErrorSnackbar({
      status: error.status,
      message: 'Failed to delete task.',
      provider: (error.data as TaskError401Res).provider as TodoIntegrationType,
      action: 'delete',
    });
  }, [deleteTaskMutation.error]);

  return {
    deleteTask: deleteTaskMutation.mutateAsync,
    isDeleting: deleteTaskMutation.isPending,
    ...deleteTaskMutation,
  };
}

import { useQuery } from '@tanstack/react-query';

import { calendarApi } from '../api';
import { Calendar } from '@demind-inc/core';

interface UseCalendarsMetaParams {
  userId?: string;
}

interface UseCalendarsMetaQueryParams {
  queryKey: ['lifestack.calendar.meta', { userId: string }];
}

export const useCalendarsMeta = ({ userId = '' }: UseCalendarsMetaParams) => {
  const calendarMetaQuery = useQuery({
    queryKey: ['lifestack.calendar.meta', { userId }],
    queryFn: ({ queryKey }: UseCalendarsMetaQueryParams) => {
      const [, { userId }] = queryKey;

      return calendarApi.getCalendarsInfo(userId).then(({ data }) => data as Calendar[]);
    },
    refetchOnWindowFocus: false,
    enabled: !!userId,
    staleTime: 15000, // 15s
  });

  return {
    ...calendarMetaQuery,
    calendarsMeta: calendarMetaQuery.data ?? [],
  };
};
